import { Button, Checkbox, DatePicker } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { I18n, Translate } from "react-redux-i18n";
import { FLEX, useDatesFilter, useStatusesFilter } from "./helpers";
import { useActions } from "../../../../../../hooks/useActions";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../../../store";
import { Dayjs } from "dayjs";
import { DATE_TIME_FORMAT } from "../../../../../../utils";
import { getSurveyInstances } from "../../../../../../store/slices/surveys/surveysService";
import { TabKeysEnum } from "../helpers";

const Filters: FC<{ activeKey: TabKeysEnum }> = ({ activeKey }) => {
  const { resetSurveyInstanceFilters$, setSurveyInstanceFilters$ } = useActions();
  const { selectedDates, setSelectedDates, resetDates } = useDatesFilter();
  const { selectedStatuses, setSelectedStatuses, resetStatuses } = useStatusesFilter();
  const filters = useSelector((state: RootState) => state.surveys.surveyInstance.filters);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const applyFilters = useCallback(() => {
    const obj = {
      ...filters,
      ...(!!selectedDates[0] && !!selectedDates[1]
        ? {
            from: (selectedDates[0] as Dayjs).format(DATE_TIME_FORMAT),
            to: (selectedDates[1] as Dayjs).format(DATE_TIME_FORMAT),
          }
        : { from: undefined, to: undefined }),
      finished: Boolean(selectedStatuses.find((it) => it === "finished")),
      notFinished: Boolean(selectedStatuses.find((it) => it === "not-finished")),
    };
    if (
      obj.notFinished !== filters.notFinished ||
      obj.finished !== filters.finished ||
      obj.from !== filters.from ||
      obj.to !== filters.to
    ) {
      setSurveyInstanceFilters$(obj);
    }
  }, [filters, selectedDates, selectedStatuses]);

  const resetFilters = useCallback(async () => {
    resetSurveyInstanceFilters$();
    resetDates();
    resetStatuses();

    const surveyId = store.getState().surveys.current?.surveyId;
    if (surveyId) {
      await store.dispatch(getSurveyInstances(surveyId));
    }
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      resetFilters();
      setIsFirstRender(false);
    }
  }, [activeKey, isFirstRender, resetFilters, setIsFirstRender]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 24, margin: "24px 0" }}>
      <div style={{ ...FLEX }}>
        <DatePicker.RangePicker
          style={{ marginRight: 24 }}
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          value={selectedDates}
          onChange={(value) => {
            if (value === null) {
              resetDates();
            } else if (Array.isArray(value)) {
              setSelectedDates(value);
            }
          }}
          inputReadOnly
        />
        <div style={{ ...FLEX, gap: 10 }}>
          <Button onClick={applyFilters} type={"primary"}>
            <Translate value={"search"} />
          </Button>
          <Button onClick={resetFilters} type={"default"}>
            <Translate value={"resetFilters"} />
          </Button>
        </div>
      </div>
      <div style={FLEX}>
        <Checkbox.Group
          onChange={(checkedValue) => {
            if (Array.isArray(checkedValue)) {
              setSelectedStatuses(checkedValue);
            }
          }}
          value={selectedStatuses}
          options={[
            { label: I18n.t("beenFinished"), value: "finished" },
            { label: I18n.t("notFinished"), value: "not-finished" },
          ]}
        />
      </div>
    </div>
  );
};

export default Filters;
