import React, { FC, ReactNode } from "react";
import { I18n } from "react-redux-i18n";
import { REQUIRED_MESSAGE } from "../../../utils/getRequiredMessage";
import validateEmail from "../../../utils/validateEmail";
import { Form, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";

type Props = {
  label?: string;
  name?: string;
  placeholder?: string;
  addonBefore?: ReactNode | null;
  validateTrigger?: string[];
};

const EmailFormItemComponent: FC<Props> = (props) => {
  const { label, name, placeholder, addonBefore = <MailOutlined />, validateTrigger = ["onBlur", "onSubmit"] } = props;
  return (
    <Form.Item
      label={label ?? I18n.t("email")}
      name={name ?? "email"}
      hasFeedback
      validateTrigger={validateTrigger}
      rules={[
        {
          required: true,
          message: REQUIRED_MESSAGE(),
        },
        () => ({
          validator(_, value) {
            if (!value || validateEmail(value)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error(I18n.t("invalidEmail")));
          },
        }),
      ]}
    >
      <Input placeholder={placeholder ?? "user@email.com"} addonBefore={addonBefore} />
    </Form.Item>
  );
};

export default EmailFormItemComponent;
