import React, { FC, useCallback, useState } from "react";
import { Modal, Tabs, Button, FormInstance } from "antd";
import { I18n, Translate } from "react-redux-i18n";
import PreviousAnswerConditionComponent from "./previousAnswerCondition";
import SubscriberProfileFiltersComponent from "../../../../../components/conditionsTag";
import FooterActions from "../../../../../components/FooterActions";

type QuestionViewSettingsModalProps = {
  open: boolean;
  onClose: () => void;
  updateWithPreviousAnswer: (value: boolean) => void;
  form: FormInstance;
};

const { TabPane } = Tabs;

const QuestionViewSettingsModal: FC<QuestionViewSettingsModalProps> = (props) => {
  const { open, onClose, updateWithPreviousAnswer, form } = props;
  const [showMassage, setShowMassage] = useState(false);

  const handleSave = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCancel = useCallback(() => {
    if (!form.getFieldValue(["previousAnswerCondition", "value"])) {
      setShowMassage(false);
    }
    onClose();
  }, [onClose]);

  const handleReset = useCallback(() => {
    updateWithPreviousAnswer(false);
    setShowMassage(false);

    form.resetFields([
      ["previousAnswerCondition", "answeredQuestionId"],
      ["previousAnswerCondition", "operator"],
      ["previousAnswerCondition", "value"],
    ]);
  }, [updateWithPreviousAnswer, form]);

  return (
    <Modal
      footer={<FooterActions onCancel={handleCancel} onSubmit={handleSave} submitLabelTranslationKey={"save"} />}
      title={I18n.t("questionDisplayLogic")}
      style={{ top: "30%", overflow: "hidden" }}
      className={"question-settings-modal"}
      onCancel={handleCancel}
      width={800}
      open={open}
    >
      <Tabs type={"card"} defaultActiveKey="1">
        <TabPane tab={I18n.t("basedOnPreviousResponses")} key="1" style={{ minHeight: 100 }}>
          <Translate value={"displayIfAnswerOn"} />
          <PreviousAnswerConditionComponent
            updateWithPreviousAnswer={updateWithPreviousAnswer}
            setShowMassage={setShowMassage}
            showMassage={showMassage}
          />
          <Button type="default" onClick={handleReset} style={{ marginLeft: "auto" }}>
            <Translate value={"reset"} />
          </Button>
        </TabPane>

        <TabPane tab={I18n.t("basedOnContactData")} key="2" style={{ minHeight: 100 }}>
          <Translate value={"displayOnlyContactsWhich"} />
          <SubscriberProfileFiltersComponent type={"questions"} variant={"inline"} title={""} noStyle />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default QuestionViewSettingsModal;
