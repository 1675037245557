import "./index.scss";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useActions } from "../../../../../hooks/useActions";
import { Button, Form, message, Select, Space } from "antd";
import InputFormItemComponent from "../../../../../components/form/items/input";
import {
  authEnumTitles,
  AuthTypeEnum,
  CallOnEnum,
  callOnTitles,
  RequestFormatEnum,
  RequestMethodEnum,
  WebhookType,
} from "../../../../../types/WebhookType";
import { RootState } from "../../../../../store";
import { useSelector } from "react-redux";
import SelectFormItemComponent from "../../../../../components/form/items/select";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import toRecord from "../../../../../utils/toRecord";
import { I18n, Translate } from "react-redux-i18n";
import FooterActions from "../../../../../components/FooterActions";
import { useRequests } from "../../../../../hooks/useRequests";

type ArrayLabelValue = Array<{
  key: string;
  value: string;
}>;

enum HttpAddon {
  HTTP = "http://",
  HTTPS = "https://",
}

const WebHookEditPage: FC<{
  readonly changeVisible: () => void;
}> = ({ changeVisible }) => {
  const { Option } = Select;
  const { updateWebhook, setCurrentWebhook } = useActions();
  const { createWebhook } = useRequests();
  const [form] = Form.useForm<WebhookType>();
  const surveyId = useSelector((state: RootState) => state.surveys.current?.surveyId);
  const webhook = useSelector((state: RootState) => state.webhooks.current);
  const webhooks = useSelector((state: RootState) => state.webhooks.list);
  const [credsPlaceholder, setCredsPlaceholder] = useState<string | undefined>();
  const [showCreds, setShowCreds] = useState(true);
  const [showFormBody, setShowFormBody] = useState(false);
  const [addon, setAddon] = useState<HttpAddon>(HttpAddon.HTTPS);

  const emptyCallOnList =
    Object.values(CallOnEnum)
      .filter((key) => !webhooks.find((wh) => wh.callOn === key && webhook?.callOn !== key))
      .map((key) => ({ value: key, label: callOnTitles()[key] })) || [];

  useEffect(() => {
    return () => {
      form.resetFields();
      setCurrentWebhook(undefined);
    };
  }, []);

  useEffect(() => {
    if (!webhook) {
      setShowCreds(false);
      return form.setFieldsValue({
        authType: AuthTypeEnum.NONE,
        requestMethod: RequestMethodEnum.POST,
      } as WebhookType);
    }
    const requestUrl = webhook?.requestUrl?.split("//") || "";
    form.setFieldsValue(
      ({
        ...webhook,
        requestUrl: requestUrl[1],
        requestBody:
          webhook?.requestFormat === RequestFormatEnum.FORM
            ? webhook?.requestBody?.split("&")?.map((it) => {
                const el = it.split("=");
                return { key: el[0], value: el[1] };
              })
            : webhook?.requestBody,
        requestHeaders: webhook?.requestHeaders
          ? Object.keys(webhook?.requestHeaders as any).map((key) => ({
              key,
              value: (webhook?.requestHeaders as any)[key],
            }))
          : [],
      } as any) || {},
    );
    setShowFormBody(webhook?.requestFormat === RequestFormatEnum.FORM);
    setAddon((requestUrl[0] + "//") as HttpAddon);
    setShowCreds(webhook?.authType !== AuthTypeEnum.NONE);
  }, [webhook]);

  const onFinish = useCallback(
    (values: WebhookType) => {
      if (!surveyId) return;
      form
        .validateFields()
        .then(async () => {
          const dto = {
            ...values,
            requestUrl: `${addon}${values.requestUrl}`,
            requestHeaders: values?.requestHeaders
              ? toRecord(values?.requestHeaders as unknown as ArrayLabelValue)
              : undefined,
            requestBody:
              values?.requestFormat === RequestFormatEnum.FORM
                ? (values?.requestBody as unknown as ArrayLabelValue)?.map((rb) => `${rb.key}=${rb.value}`).join("&")
                : values?.requestBody,
          };
          if (!webhook?.webhookId) {
            await createWebhook(surveyId, dto);
            return changeVisible();
          }
          updateWebhook({
            surveyId,
            webhook: dto,
            webhookId: webhook?.webhookId,
          });
        })
        .catch(console.log);
    },
    [surveyId, form, addon, webhook, createWebhook, changeVisible],
  );

  function onValuesChange(values: Record<string, string>) {
    if (values?.authType) {
      setShowCreds(values.authType !== AuthTypeEnum.NONE);
      setCredsPlaceholder(values.authType === AuthTypeEnum.BASIC ? "USERNAME : PASSWORD" : undefined);
      form.resetFields(["credentials"]);
    } else if (values?.requestFormat) {
      form.resetFields(["requestBody"]);
      setShowFormBody(values.requestFormat === RequestFormatEnum.FORM);
    }
  }

  function onCancel() {
    changeVisible();
  }

  return (
    <div className={"webhook-edit-page"}>
      <div className={"form-container"}>
        <Form
          form={form}
          scrollToFirstError
          onFinish={onFinish}
          layout={"horizontal"}
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 5 }}
          onValuesChange={onValuesChange}
          onFinishFailed={() => message.error(I18n.t("fillRequiredFields"))}
        >
          <SelectFormItemComponent required name={"callOn"} values={emptyCallOnList} label={I18n.t("callOn")} />
          <InputFormItemComponent name={"description"} label={I18n.t("description")} />
          <Form.Item required label={I18n.t("request")}>
            <Space.Compact>
              <SelectFormItemComponent
                noStyle
                required
                hasFeedback={false}
                name={"requestMethod"}
                style={{ width: "22%" }}
                label={I18n.t("method")}
                values={Object.values(RequestMethodEnum).map((it) => ({
                  label: it,
                  value: it,
                }))}
              />
              <InputFormItemComponent
                noStyle
                style={{ width: "77%", marginLeft: "1%" }}
                addonBefore={
                  <Select value={addon} className="select-before" onSelect={(value: any) => setAddon(value)}>
                    {Object.values(HttpAddon).map((key) => (
                      <Option key={key} value={key}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                }
                placeHolder={"example.com"}
                label={"example.com"}
                name={"requestUrl"}
                onChange={(e) => {
                  if (e.startsWith(HttpAddon.HTTPS) || e.startsWith(HttpAddon.HTTP)) {
                    const url = e.split("//");
                    setAddon((url[0] + "//") as HttpAddon);
                    form.setFieldValue("requestUrl", url[1]);
                  }
                }}
              />
            </Space.Compact>
          </Form.Item>

          <SelectFormItemComponent
            required
            name={"authType"}
            hasFeedback={false}
            label={I18n.t("authorization")}
            values={Object.values(AuthTypeEnum).map((it) => ({
              label: authEnumTitles[it],
              value: it,
            }))}
          />

          {showCreds && (
            <InputFormItemComponent
              name={"credentials"}
              placeHolder={credsPlaceholder}
              label={I18n.t("authorizationInfo")}
              pattern={credsPlaceholder ? /[A-Za-z0-9]:[A-Za-z0-9]/ : undefined}
            />
          )}

          <Form.Item label={I18n.t("headers")} className={"no-error-label"}>
            <Form.List name="requestHeaders">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <Space.Compact key={key} className={"input-group"}>
                      <InputFormItemComponent label={I18n.t("key")} name={[name, "key"]} noStyle />
                      <InputFormItemComponent label={I18n.t("value")} name={[name, "value"]} noStyle />
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space.Compact>
                  ))}
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button block type="dashed" onClick={add} icon={<PlusOutlined />}>
                      <Translate value={"add"} />
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>

          <SelectFormItemComponent
            required
            name={"requestFormat"}
            label={I18n.t("format")}
            values={Object.values(RequestFormatEnum).map((it) => ({
              label: it,
              value: it,
            }))}
          />
          {!showFormBody ? (
            <InputFormItemComponent
              showTextArea
              name={"requestBody"}
              style={{ color: "darkblue" }}
              label={I18n.t("requestBody")}
              onChange={(ugly) => {
                try {
                  const obj = JSON.parse(ugly);
                  const pretty = JSON.stringify(obj, undefined, 2);
                  form.setFieldValue("requestBody", pretty);
                } catch (e) {}
              }}
            />
          ) : (
            <Form.Item required className={"no-error-label"} label={I18n.t("requestBody")}>
              <Form.List name="requestBody">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }) => (
                      <Space.Compact key={key} className={"input-group"}>
                        <InputFormItemComponent label={I18n.t("key")} name={[name, "key"]} noStyle />
                        <InputFormItemComponent label={I18n.t("value")} name={[name, "value"]} noStyle />
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space.Compact>
                    ))}
                    <Form.Item>
                      <Button block type="dashed" onClick={add} icon={<PlusOutlined />}>
                        <Translate value={"add"} />
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          )}
        </Form>
        <FooterActions
          width={620}
          onCancel={onCancel}
          onSubmit={form.submit}
          cancelLabelTranslationKey={"back"}
          submitLabelTranslationKey={"save"}
        />
      </div>
    </div>
  );
};

export default WebHookEditPage;
