import React, { FC, useCallback, useEffect, useState } from "react";
import { Form, message, Modal } from "antd";
import FooterActions from "../../../../../../components/FooterActions";
import InputFormItemComponent from "../../../../../../components/form/items/input";
import { I18n, Translate } from "react-redux-i18n";
import { getSurveyInstanceUrl } from "./helpers";
import { useRequests } from "../../../../../../hooks/useRequests";
import QrCodeGenerator from "../../../../../../components/qrGenerator";
import { QrCodeType } from "../../../../../../types/qrCode";
import { useDistributionChannelsApi } from "../../../../../../hooks/useDistributionChannelsApi";
import { DistributionChannelTypeEnum } from "../../../../../../types/DistributionChannel";

type QrManagementModalProps = {
  readonly surveyId: string;
  readonly open: boolean;
  readonly onCancel: () => void;
  readonly qrCode?: QrCodeType;
};

const QrModal: FC<QrManagementModalProps> = (props) => {
  const { open, onCancel, surveyId, qrCode } = props;
  const [saving, setSaving] = useState(false);
  const { createQrCode } = useRequests();
  const { createDistributionChannel } = useDistributionChannelsApi();
  const [form] = Form.useForm<{ receiver: string }>();

  const handleSubmit = useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        setSaving(true);
        const newQr = await createQrCode({
          surveyId: surveyId,
          receiver: values.receiver,
        });
        await createDistributionChannel({
          channelType: DistributionChannelTypeEnum.QR,
          qrCodeId: newQr.qrCodeId,
          surveyId: surveyId,
          description: I18n.t("qrCode"),
        });
      })
      .catch(() => message.error(I18n.t("fillRequiredFields")))
      .finally(() => setSaving(false));
  }, [form, surveyId, setSaving, createQrCode, createDistributionChannel]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  useEffect(() => {
    form.setFieldValue("receiver", qrCode?.receiver);
  }, []);

  return (
    <Modal
      closable
      open={open}
      width={500}
      onCancel={handleCancel}
      style={{ overflow: "hidden" }}
      title={<Translate value={!!qrCode?.receiver ? "shareQrAnsUrl" : "qrAndUrlCreating"} />}
      footer={
        qrCode?.receiver ? null : (
          <FooterActions
            gap={10}
            loading={saving}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            submitLabelTranslationKey={"createAndContinue"}
          />
        )
      }
    >
      <Form form={form} layout={"vertical"} style={{ marginTop: 24 }}>
        <Form.Item shouldUpdate noStyle>
          {({ setFieldValue }) => {
            const onChange = (value: string) => {
              const formattedValue = value.replace(/ /g, "_");
              setFieldValue("receiver", formattedValue);
            };
            return (
              <InputFormItemComponent
                required={true}
                name={"receiver"}
                onChange={onChange}
                pattern={/^[a-zA-Z0-9_-]+$/}
                disabled={!!qrCode?.receiver}
                label={I18n.t("identifier")}
                errorText={I18n.t("identifierErrorMsg")}
              />
            );
          }}
        </Form.Item>
      </Form>

      {qrCode && (
        <>
          {/*<SocialMediumShare urlToShare={getSurveyInstanceUrl(surveyId, qrCode.receiver)} />*/}
          <QrCodeGenerator
            surveyId={surveyId}
            qrCodeId={qrCode.qrCodeId}
            receiver={qrCode.receiver}
            url={getSurveyInstanceUrl(surveyId, qrCode.receiver, DistributionChannelTypeEnum.URL_QR)}
            initialData={qrCode?.qrProperties ? { ...qrCode.qrProperties } : undefined}
          />
        </>
      )}
    </Modal>
  );
};

export default QrModal;
