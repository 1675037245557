import { useAppDispatch } from "../store";
import { useCallback } from "react";
import { requestApi } from "../utils/requestApi";
import { POST, PUT } from "../types/httpMethod";
import { DistributionType } from "../types/distributionType";
import {
  addDistributionToList,
  updateCurrentDistribution,
  updateDistributionInList,
} from "../store/slices/distributions/distributionsService";

interface IUseDistributionsApi {
  createDistribution: (payload: DistributionType) => Promise<DistributionType>;
  updateDistribution: (payload: DistributionType) => Promise<DistributionType>;
}

export const useDistributionsApi = (surveyId: string): IUseDistributionsApi => {
  const dispatch = useAppDispatch();

  const createDistribution = useCallback(
    async (payload: DistributionType): Promise<DistributionType> => {
      try {
        const newPayload = { ...payload, sourceId: "CONTACTS" };
        const res = await requestApi(POST, "/distribution_list", newPayload, false, true, undefined, { surveyId });
        dispatch(addDistributionToList(res));
        return res;
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  const updateDistribution = useCallback(
    async (payload: DistributionType): Promise<DistributionType> => {
      try {
        const res = await requestApi(PUT, `/distribution_list`, payload, false, true, undefined, { surveyId });
        dispatch(updateDistributionInList(res));
        dispatch(updateCurrentDistribution(res));
        return res;
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  return {
    createDistribution: createDistribution,
    updateDistribution: updateDistribution,
  };
};
