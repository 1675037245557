import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestApi } from "../../../utils/requestApi";
import { DELETE, GET, POST, PUT } from "../../../types/httpMethod";
import { message } from "antd";
import { I18n } from "react-redux-i18n";
import { DistributionStatus, DistributionType } from "../../../types/distributionType";
import {
  addToList,
  removeFromList,
  setCurrent,
  setList,
  updateCurrent,
  updateInList,
  updateStatusInList,
} from "./distributionsSlice";
import {
  DistributionStatusAction,
  getDistributionStatusByAction,
  getMessageKeyByAction,
} from "../../../utils/getDistributionStatusByAction";
import { SubscribersUploadResponseType } from "../../../types/subscribersUploadResponseType";

export const getDistributionsBySurveyId = createAsyncThunk<void, string>(
  "distributions/getDistributionsBySurveyId",
  async (surveyId, { dispatch }) => {
    try {
      const res = await requestApi(GET, `/distribution_list/survey/${surveyId}`, {});
      dispatch(setDistributionsList(res));
    } catch (e) {
      console.log("Error fetching distributions:", e);
      throw e;
    }
  },
);

export const getDistributionById = createAsyncThunk<
  void,
  {
    readonly surveyId: string;
    readonly distributionId: string;
  }
>("distributions/getDistributionById", async ({ surveyId, distributionId }, { dispatch }) => {
  try {
    const res = await requestApi(GET, `/distribution_list/${distributionId}`, {});
    dispatch(setCurrentDistribution(res));
  } catch (e) {
    throw e;
  }
});

export const createDistribution = createAsyncThunk<
  Promise<DistributionType>,
  {
    readonly surveyId: string;
    readonly distribution: DistributionType;
  }
>("distributions/createDistribution", async ({ surveyId, distribution }, { dispatch }) => {
  try {
    const res = await requestApi(POST, "/distribution_list", distribution, false, true, undefined, { surveyId });
    dispatch(addDistributionToList(res));
    return res;
  } catch (e) {
    throw e;
  }
});

export const updateDistribution = createAsyncThunk<
  void,
  {
    readonly surveyId: string;
    readonly distribution: DistributionType;
  }
>("distributions/updateDistribution", async ({ surveyId, distribution }, { dispatch }) => {
  try {
    const res = await requestApi(PUT, `/distribution_list`, distribution, false, true, undefined, { surveyId });
    dispatch(updateDistributionInList(res));
    dispatch(updateCurrentDistribution(res));
  } catch (e) {
    throw e;
  }
});

export const changeDistributionStatus = createAsyncThunk<
  void,
  {
    readonly surveyId: string;
    readonly distributionId: string;
    readonly status: DistributionStatusAction;
  }
>("distributions/changeDistributionStatus", async ({ surveyId, distributionId, status }, { dispatch }) => {
  try {
    await requestApi(PUT, `/distribution_list/${distributionId}/${status}`, {}, false, true, undefined, {
      surveyId,
    });
    dispatch(updateDistributionStatusInList({ distributionId, status: getDistributionStatusByAction(status) }));
    message.success(I18n.t(getMessageKeyByAction(status)));
  } catch (e) {
    throw e;
  }
});

export const deleteDistributionById = createAsyncThunk<void, string>(
  "distributions/deleteDistributionById",
  async (distributionId, { dispatch }) => {
    try {
      await requestApi(DELETE, `/distribution_list/${distributionId}`, {});
      dispatch(removeDistributionFromList(distributionId));
      message.success(I18n.t("distributionDeleted"));
    } catch (e) {
      throw e;
    }
  },
);

export const uploadSubscribersCsv = createAsyncThunk<
  void,
  {
    readonly surveyId: string;
    readonly distributionId: string;
    readonly subscriberList: any;
  }
>("distributions/uploadSubscribersCsv", async ({ surveyId, distributionId, subscriberList }, { dispatch }) => {
  try {
    if (!subscriberList) return;
    const res: SubscribersUploadResponseType = await requestApi(
      POST,
      `/distribution_list/${surveyId}/subscribers-list/${distributionId}`,
      { subscriberList },
      true,
    );

    dispatch(
      updateDistributionInList({
        distributionId: distributionId,
        subsFileCount: res.count ?? 0,
      } as DistributionType),
    );

    message.success(`${I18n.t("fileUploaded")} ${I18n.t("subscribersCountInFile")} ${res.count}`);
  } catch (e) {
    console.log(e);
  }
});

export const deleteSubscribersCsv = createAsyncThunk<
  void,
  {
    readonly surveyId: string;
    readonly distributionId: string;
  }
>("distributions/uploadSubscribersCsv", async ({ surveyId, distributionId }) => {
  try {
    await requestApi(DELETE, `/distribution_list/${surveyId}/subscribers-list/${distributionId}`, {});
    message.success(I18n.t("fileDeleted"));
  } catch (e) {
    console.log(e);
  }
});

export const setDistributionsList = createAsyncThunk<void, Array<DistributionType>>(
  "distributions/setDistributionsList",
  (distributions, { dispatch }) => {
    dispatch(setList(distributions));
  },
);

export const setCurrentDistribution = createAsyncThunk<void, DistributionType | undefined>(
  "distributions/setCurrentDistribution",
  (distribution, { dispatch }) => {
    dispatch(setCurrent(distribution));
  },
);

export const updateCurrentDistribution = createAsyncThunk<void, Partial<DistributionType>>(
  "distributions/updateCurrentDistribution",
  (data, { dispatch }) => {
    dispatch(updateCurrent(data));
  },
);

export const removeDistributionFromList = createAsyncThunk<void, string>(
  "distributions/removeDistributionFromList",
  (distributionId, { dispatch }) => {
    dispatch(removeFromList(distributionId));
  },
);

export const addDistributionToList = createAsyncThunk<void, DistributionType>(
  "distributions/addDistributionToList",
  (distribution, { dispatch }) => {
    dispatch(addToList(distribution));
  },
);

export const updateDistributionInList = createAsyncThunk<void, DistributionType>(
  "distributions/updateDistributionInList",
  (distribution, { dispatch }) => {
    dispatch(updateInList(distribution));
  },
);

export const updateDistributionStatusInList = createAsyncThunk<
  void,
  {
    readonly distributionId: string;
    readonly status: DistributionStatus;
  }
>("distributions/updateDistributionStatusInList", (values, { dispatch }) => {
  dispatch(updateStatusInList(values));
});
