import React, { FC, Key, useCallback, useEffect, useState } from "react";
import { Button, Checkbox, message, Modal, Popconfirm, Table } from "antd";
import { I18n, Translate } from "react-redux-i18n";
import { DeleteOutlined, DownloadOutlined, MenuOutlined } from "@ant-design/icons";
import { Columns, filterColumns } from "./helpers";
import { ColumnType, Instance } from "../../../../../../types/resultsPageTypes";
import { useActions } from "../../../../../../hooks/useActions";
import { Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import FooterActions from "../../../../../../components/FooterActions";
import { TabKeysEnum } from "../helpers";

const TableComponent: FC<{ activeKey: TabKeysEnum, list: any}> = ({ activeKey, list }) => {
  const { id: surveyId } = useParams();
  const { setSurveyInstancePageable$, setSurveyInstanceFilters$ } = useActions();
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [selectedColumns, setSelectedColumns] = useState<ColumnType | undefined>();
  const data = useSelector((state: RootState) => state.surveys.surveyInstance.data);
  const { pageable: pagination, filters } = useSelector((state: RootState) => state.surveys.surveyInstance);

  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: Array<Instance>) => {
      setSelectedRows(selectedRows.map((it) => it.instanceId));
    },
  };

  const onColumnsChange = useCallback(
    (cols: ColumnType) => {
      const updatedAnswersFilters = Object.keys(cols).reduce((result: Record<string, string[] | undefined>, key) => {
        if (filters.answersFilter[key]) {
          result[key] = filters.answersFilter[key];
        }
        return result;
      }, {} as Record<string, string[] | undefined>);

      const wasUpdated = Object.keys(filters.answersFilter).find((key) => !updatedAnswersFilters[key]);

      setSelectedColumns(cols);
      if (wasUpdated) {
        setSurveyInstanceFilters$({ ...filters, answersFilter: updatedAnswersFilters });
      }
    },
    [filters],
  );

  useEffect(() => {
    if (data?.columns) {
      setSelectedColumns(data?.columns);
    }
  }, [data?.columns, activeKey]);

  const onPageChange = useCallback(
    (pageNumber: number, pageSize: number) => {
      setSurveyInstancePageable$({ ...pagination, page: pageNumber - 1, size: pageSize });
    },
    [pagination],
  );

  if (!surveyId) {
    return <Outlet />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Actions
        surveyId={surveyId}
        allColumns={data?.columns}
        selectedRows={selectedRows}
        selectedColumns={selectedColumns}
        onColumnsChange={onColumnsChange}
        resetSelectedRows={() => setSelectedRows([])}
      />
      <Table
        pagination={{
          size: "small",
          showSizeChanger: true,
          onChange: onPageChange,
          total: pagination.total,
          pageSize: pagination.size,
          current: pagination.page + 1,
          defaultPageSize: pagination.size,
        }}
        scroll={{ x: 1000 }}
        style={{ marginTop: 24 }}
        dataSource={data?.instances}
        rowKey={(record) => record?.instanceId + ""}
        columns={Columns(surveyId, selectedColumns, data?.columns, list)}
        rowSelection={{ type: "checkbox", ...rowSelection, selectedRowKeys: selectedRows }}
      />
    </div>
  );
};

type ActionProps = {
  surveyId?: string;
  selectedRows: Array<string>;
  resetSelectedRows: () => void;
  allColumns?: ColumnType;
  selectedColumns?: ColumnType;
  onColumnsChange: (cols: ColumnType) => void;
};

const Actions = (props: ActionProps) => {
  const {
    surveyId,
    selectedRows,
    resetSelectedRows,
    selectedColumns: selectedColumnsReadonly,
    allColumns = {},
    onColumnsChange,
  } = props;

  const [columnsModalVisible, setColumnsModalVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<Array<string>>([]);
  const { deleteSurveyInstancesByIds, getSurveyReportXlsx } = useActions();

  const handleDelete = useCallback(async () => {
    if (surveyId) {
      const res: any = await deleteSurveyInstancesByIds({ surveyId, instanceIds: selectedRows });
      if (res.payload) {
        resetSelectedRows();
      }
    }
  }, [surveyId, selectedRows]);

  const handleDownload = useCallback(() => {
    if (surveyId) {
      getSurveyReportXlsx(surveyId);
    }
  }, [surveyId]);

  const handleColumnsChange = useCallback(() => {
    if (!selectedColumns.length) {
      return message.warning(I18n.t("columnsNotFound"));
    }
    setColumnsModalVisible(true);
  }, [selectedColumns]);

  const handleCloseColumnsChangeModal = useCallback(() => {
    setColumnsModalVisible(false);
  }, []);

  const handleSelect = useCallback((values: Array<string>) => {
    if (!values.length) {
      return message.error(I18n.t("atLeastOneColumnsMustBeSelected"));
    }
    setSelectedColumns(values);
  }, []);

  const handleSubmit = useCallback(() => {
    const filtered = filterColumns(allColumns, selectedColumns);
    onColumnsChange(filtered);
    setColumnsModalVisible(false);
  }, [selectedColumns]);

  const handleSelectAll = useCallback(() => {
    const allKeys = Object.keys(allColumns);
    if (Object.keys(allColumns).length === selectedColumns.length) {
      resetSelectedRows();
      setSelectedColumns([allKeys[0]]);
    } else {
      setSelectedColumns(allKeys);
    }
  }, [allColumns, selectedColumns, setSelectedColumns]);

  useEffect(() => {
    if (selectedColumnsReadonly) {
      setSelectedColumns(Object.keys(selectedColumnsReadonly));
    }
  }, [selectedColumnsReadonly]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        position: "absolute",
        top: 0,
        right: 0,
        gap: 12,
      }}
    >
      <Popconfirm
        title={I18n.t("deleteSelectedElementsConfirmation")}
        disabled={!selectedRows.length}
        onConfirm={handleDelete}
      >
        <Button type={"default"} icon={<DeleteOutlined />} disabled={!selectedRows.length}>
          <Translate value={"delete"} />
        </Button>
      </Popconfirm>

      <Button type={"default"} icon={<DownloadOutlined />} onClick={handleDownload}>
        <Translate value={"download"} />
      </Button>

      <Button
        type={"default"}
        style={{ marginLeft: 20 }}
        onClick={handleColumnsChange}
        icon={<MenuOutlined style={{ rotate: "90deg" }} />}
      >
        <Translate value={"questionsFilter"} />
      </Button>

      {selectedColumns && allColumns && (
        <Modal
          width={400}
          closable={false}
          open={columnsModalVisible}
          title={
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
              <Translate value={"selectQuestions"} />
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  handleSelectAll();
                }}
              >
                <Translate value={"selectAll"} />
              </a>
            </div>
          }
          onCancel={handleCloseColumnsChangeModal}
          footer={<FooterActions onSubmit={handleSubmit} onCancel={handleCloseColumnsChangeModal} />}
        >
          <div style={{ maxHeight: "40vh", overflowX: "scroll" }}>
            <Checkbox.Group
              style={{ display: "flex", flexDirection: "column", gap: 3 }}
              options={Object.keys(allColumns).map((questionId) => ({
                label: allColumns[questionId]?.question,
                value: questionId,
              }))}
              onChange={(values) => handleSelect(values as Array<string>)}
              value={selectedColumns}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TableComponent;
