import React, { FC, ReactNode, useState, useCallback } from "react";
import { FrownOutlined, HeartFilled, MehOutlined, SmileOutlined, StarFilled } from "@ant-design/icons";
import { Rate } from "antd";
import { NpsIconEnum } from "../types/npsIconEnum";

export const smileIcons: Record<number, ReactNode> = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <MehOutlined />,
  5: <SmileOutlined />,
  6: <SmileOutlined />,
};

const Rating: FC<{
  readonly iconType?: NpsIconEnum;
  readonly defaultValue: number;
  readonly maxValue: number;
}> = ({ iconType, defaultValue, maxValue }) => {
  const [currentValue, setCurrentValue] = useState<number>(defaultValue);

  const getSmile = useCallback(
    ({ index }: { index?: number }) => {
      if (index === undefined) return null;
      const isActive = index + 1 === currentValue;
      return <span style={{ color: isActive ? "#fadb14" : "#d9d9d9" }}>{smileIcons[index + 1]}</span>;
    },
    [currentValue],
  );

  const getNumeric = useCallback(
    ({ index }: { index?: number }) => {
      if (index === undefined) return null;
      const isActive = index + 1 === currentValue;
      return <span style={{ color: isActive ? "#fadb14" : "#d9d9d9" }}>{index + 1}</span>;
    },
    [currentValue],
  );

  const getCharacter = useCallback(() => {
    if (isIcon(NpsIconEnum.NUMERIC)) {
      return getNumeric;
    } else if (isIcon(NpsIconEnum.SMILE)) {
      return getSmile;
    } else if (isIcon(NpsIconEnum.HEART)) {
      return <HeartFilled />;
    } else {
      return <StarFilled />;
    }
  }, [iconType, getSmile, getNumeric]);

  function isIcon(type: NpsIconEnum) {
    return iconType === type;
  }

  const handleRateChange = (value: number) => {
    setCurrentValue(value);
  };

  return (
    <Rate
      style={{
        fontSize:
          maxValue === 5 ? 33 : isIcon(NpsIconEnum.STAR) || isIcon(NpsIconEnum.HEART) ? (maxValue === 6 ? 25 : 14) : 23,
      }}
      value={currentValue}
      onChange={handleRateChange}
      count={isIcon(NpsIconEnum.SMILE) ? maxValue : maxValue}
      character={getCharacter()}
    />
  );
};

export default Rating;
