import React, { FC, useCallback, useState } from "react";
import IconButton from "../iconButton";
import { CopyLink } from "../../../../../../../components/icons";
import BaseCreateModal from "../baseCreateModal";
import { Button, Input, Space } from "antd";
import { useActions } from "../../../../../../../hooks/useActions";
import { useParams } from "react-router-dom";
import { I18n, Translate } from "react-redux-i18n";
import { getSurveyInstanceUrl } from "../../../distributionAndNotification/qrCodes/helpers";
import { copyToClipboard } from "../../../../../../../utils/copyToClipboard";
import { DistributionChannelTypeEnum } from "../../../../../../../types/DistributionChannel";

type Props = {
  readonly showButton?: boolean;
  readonly openModal?: boolean;
};

const getDefaultProps = (surveyId: string) => ({
  initialValues: {
    link: getSurveyInstanceUrl(surveyId, "web", DistributionChannelTypeEnum.URL),
  },
  showButton: true,
  openModal: false,
});

const CopyLinkButton: FC<Props> = (props) => {
  const { id: surveyId } = useParams();
  const { initialValues, showButton, openModal } = { ...getDefaultProps(surveyId ?? ""), ...props };
  const [modalOpen, setModalOpen] = useState(openModal ?? false);
  const { setCurrentDistributionChannel } = useActions();

  const handleClick = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const handleCancel = useCallback(() => {
    setModalOpen(false);
    setCurrentDistributionChannel(undefined);
  }, [setCurrentDistributionChannel, setModalOpen]);

  const handleCopy = useCallback(() => {
    copyToClipboard(initialValues.link);
    handleCancel();
  }, [initialValues.link, handleCancel]);

  return (
    <>
      {showButton && <IconButton icon={<CopyLink />} label={"Web link"} onClick={handleClick} />}
      <BaseCreateModal
        title={I18n.t("linkToSurvey")}
        children={
          <Space.Compact style={{ margin: "24px 0", width: "100%" }}>
            <Input disabled value={initialValues.link} />
            <Button type="primary" onClick={handleCopy}>
              <Translate value={"copy"} />
            </Button>
          </Space.Compact>
        }
        onCancel={handleCancel}
        open={modalOpen}
        onSubmit={null}
      />
    </>
  );
};

export default CopyLinkButton;
