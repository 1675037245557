import React, { FC } from "react";
import { Checkbox, Form } from "antd";
import { GrowingList } from "../helpers";
import { I18n, Translate } from "react-redux-i18n";
import Divider from "../../../../../../components/divider";
import { SurveyLanguage } from "../../../../../../types/language";
import useSurveyDisabled from "../../../../../../hooks/useSurveyDisabled";
import CustomTemplate from ".././answerTemplates/custom";

type RangingTemplateProps = {
  readonly langIndex: number;
  readonly lang: SurveyLanguage;
};

const RangingTemplate: FC<RangingTemplateProps> = (props) => {
  const disabled = useSurveyDisabled();

  return (
    <Form.Item shouldUpdate noStyle>
      <div style={{ marginTop: 34, marginBottom: 20 }}>
        <Divider translationCode={"answers"} />
        <GrowingList
          {...props}
          disabled={disabled}
          dataKey={"answerChoices"}
          addButtonLabel={I18n.t("addAnswerVariant")}
        />
        <CustomTemplate />

        <div style={{ display: "flex", flexDirection: "column", gap: 14 }}>
          <Form.Item name={["answerTemplate", "mixedAnswers"]} valuePropName={"checked"} noStyle>
            <Checkbox children={<Translate value={"mixOptions"} />} />
          </Form.Item>
          <Form.Item name={["answerTemplate", "hideLineOrder"]} valuePropName={"checked"} noStyle>
            <Checkbox children={<Translate value={"hideLineNumbers"} />} />
          </Form.Item>
        </div>
      </div>
    </Form.Item>
  );
};

export default RangingTemplate;
