import { FC, useState, useEffect, useRef } from "react";
import { BoldItalicUnderlineToggles, MDXEditor, toolbarPlugin, UndoRedo } from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import "./index.scss";
import { Form } from "antd";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { SmileOutlined } from "@ant-design/icons";
import { REQUIRED_MESSAGE } from "../../utils/getRequiredMessage";

type Props = {
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly name: string | (string | number)[];
  readonly label: string;
  readonly showEmoji?: boolean;
};

const MdxEditor: FC<Props> = (props) => {
  const { name, label, disabled, showEmoji } = props;
  const [emojiPickerVisible, setEmojiPickerVisible] = useState<boolean>(false);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  const editorRef = useRef<any>(null);

  const formProps = {
    ...props,
    rules: [{ required: props.required, message: REQUIRED_MESSAGE() }],
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (emojiPickerVisible && emojiPickerRef.current && !emojiPickerRef.current.contains(event.target as Node)) {
        setEmojiPickerVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPickerVisible]);

  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue, setFieldValue }) => {
        const markdownValue$: string = getFieldValue(name);

        const handleMarkdownChange$ = (newMarkdownValue: string) => {
          setFieldValue(name, newMarkdownValue);
        };

        const handleEmojiSelect = (emoji: any) => {
          if (editorRef.current) {
            editorRef.current.insertMarkdown(emoji.native);
            const newMarkdownValue = markdownValue$ + emoji.native;
            handleMarkdownChange$(newMarkdownValue);
            setTimeout(() => {
              editorRef.current.setMarkdown(newMarkdownValue);
            }, 0);
          }
        };

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Item {...formProps} label={label} name={name} style={{ flex: 1 }}>
              <MDXEditor
                ref={editorRef}
                markdown={markdownValue$ ?? ""}
                onChange={(newValue: string) => {
                  if (disabled) {
                    return;
                  }
                  handleMarkdownChange$(newValue);
                }}
                className={`mdxeditor-container ${disabled ? "mdxeditor-disabled" : ""}`}
                plugins={[
                  toolbarPlugin({
                    toolbarContents: () => (
                      <>
                        <UndoRedo />
                        <BoldItalicUnderlineToggles />
                        {showEmoji && (
                          <SmileOutlined
                            onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}
                            style={{ fontSize: "20px", marginLeft: "8px", cursor: "pointer" }}
                          />
                        )}
                      </>
                    ),
                  }),
                ]}
              />
            </Form.Item>

            {emojiPickerVisible && (
              <div ref={emojiPickerRef} style={{ position: "absolute", zIndex: 999, right: -360 }}>
                <Picker data={data} onEmojiSelect={handleEmojiSelect} />
              </div>
            )}
          </div>
        );
      }}
    </Form.Item>
  );
};

export default MdxEditor;
