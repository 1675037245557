import React, { FC, useState } from "react";
import { Checkbox, Form } from "antd";
import InputFormItemComponent from "../../../../../../components/form/items/input";
import { GrowingList } from "../helpers";
import HiddenValueFormItemComponent from "../../../../../../components/form/items/hiddenValue";
import { I18n, Translate } from "react-redux-i18n";
import Divider from "../../../../../../components/divider";
import { SurveyLanguage } from "../../../../../../types/language";
import useSurveyDisabled from "../../../../../../hooks/useSurveyDisabled";
import CustomTemplate from ".././answerTemplates/custom";
import { TemplateEnum } from "../../../../../../types/templateEnum";
import SelectFormItemComponent from "../../../../../../components/form/items/select";

type ChoicesTemplateProps = {
  readonly langIndex: number;
  readonly lang: SurveyLanguage;
  templateType: TemplateEnum;
};

const ChoicesTemplate: FC<ChoicesTemplateProps> = (props) => {
  const [includeOther, setIncludeOther] = useState(false);
  const disabled = useSurveyDisabled();
  const [listLength, setListLength] = useState(0);

  return (
    <>
      <Divider translationCode={"answers"} />
      <GrowingList
        {...props}
        disabled={disabled}
        dataKey={"answerChoices"}
        addButtonLabel={I18n.t("addAnswerVariant")}
        onListLengthChange={setListLength}
      />

      <Form.Item shouldUpdate noStyle>
        {({ resetFields, setFieldValue, getFieldValue }) => {
          const addCustomAnswer = getFieldValue(["answerTemplate", "addCustomAnswer"]);
          if (addCustomAnswer !== includeOther) {
            setIncludeOther(addCustomAnswer);
          }
          return (
            <div style={{ marginTop: 34, marginBottom: 20 }}>
              {props.templateType === TemplateEnum.MULTIPLE_CHOICE && (
                <SelectFormItemComponent
                  label={I18n.t("fixedNumberOfSelectedOptions")}
                  name={["answerTemplate", "maxOptionLimit"]}
                  values={Array.from({ length: listLength }, (_, i) => ({
                    label: i + 1,
                    value: i + 1,
                  }))}
                  style={{ width: "8em", textAlign: "center", paddingRight: "0.5em" }}
                />
              )}
              <CustomTemplate />
              <Checkbox
                disabled={disabled}
                checked={includeOther}
                onChange={(e) => {
                  resetFields([["answerTemplate", "textTranslations"]]);
                  setIncludeOther(e.target.checked);
                  setFieldValue(["answerTemplate", "addCustomAnswer"], e.target.checked);
                }}
                children={<Translate value={"includeYourChoiceInAnswers"} />}
              />
            </div>
          );
        }}
      </Form.Item>

      {includeOther && (
        <div>
          <HiddenValueFormItemComponent
            name={["answerTemplate", "textTranslations", props.langIndex, "language"]}
            value={props.lang}
          />
          <InputFormItemComponent
            name={["answerTemplate", "textTranslations", props.langIndex, "text"]}
            label={I18n.t("titleForYourChoice")}
            hasFeedback
          />
        </div>
      )}
    </>
  );
};

export default ChoicesTemplate;
