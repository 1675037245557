import { CopyOutlined, DownloadOutlined } from "@ant-design/icons";
import { FC, useCallback } from "react";
import { DistributionChannel, DistributionChannelTypeEnum } from "../../../../../../types/DistributionChannel";
import { copyToClipboard } from "../../../../../../utils/copyToClipboard";
import { getIframeValue } from "../../../../../../utils";
import { FileTypesEnum, getSurveyInstanceUrl, QrStylesEnum } from "../../distributionAndNotification/qrCodes/helpers";
import QRCodeStyling from "qr-code-styling";
import { QrCodesData } from "../../../../../../components/qrGenerator";

const style = { cursor: "pointer" };

type Props = {
  readonly data: DistributionChannel;
};

const TableActions: FC<Props> = ({ data }) => {
  const { channelType } = data;

  const isChannelType = useCallback(
    (type: DistributionChannelTypeEnum) => {
      return type === channelType;
    },
    [channelType],
  );

  const handleCopy = useCallback(() => {
    const url = getSurveyInstanceUrl(data?.survey?.surveyId ?? "", data?.receiver ?? "web", channelType);
    copyToClipboard(isChannelType(DistributionChannelTypeEnum.IFRAME) ? getIframeValue(url) : url);
  }, [isChannelType, data]);

  const handleDownload = useCallback(() => {
    const qr = data.qrCode;
    const qrOptions = QrCodesData[qr?.qrProperties?.style ?? QrStylesEnum.STANDARD];

    const options = {
      data: getSurveyInstanceUrl(data?.surveyId ?? "", qr?.receiver ?? "", channelType),
      image: qr?.qrLogoUrl,
      ...qrOptions,
      dotsOptions: {
        ...qrOptions.dotsOptions,
        color: qr?.qrProperties?.color,
      },
    };

    const qrCodeStyling = new QRCodeStyling({
      ...options,
      width: Number(qr?.qrProperties?.size ?? qrOptions.width),
      height: Number(qr?.qrProperties?.size ?? qrOptions.height),
    });

    qrCodeStyling
      .download({
        name: `QR_${data.qrCode?.receiver}`,
        extension: data.qrCode?.qrProperties?.fileType ?? FileTypesEnum.PNG,
      })
      .catch(console.log);
  }, [data]);

  if (isChannelType(DistributionChannelTypeEnum.URL) || isChannelType(DistributionChannelTypeEnum.IFRAME)) {
    return <CopyOutlined style={style} onClick={handleCopy} />;
  }

  if (isChannelType(DistributionChannelTypeEnum.QR) || isChannelType(DistributionChannelTypeEnum.URL_QR)) {
    return <DownloadOutlined style={style} onClick={handleDownload} />;
  }

  return <></>;
};

export default TableActions;
