import React, { FC, useCallback, useState } from "react";
import { Button, Form, message, Modal } from "antd";
import { I18n, Translate } from "react-redux-i18n";
import { ShareAltOutlined } from "@ant-design/icons";
import InputFormItemComponent from "./form/items/input";
import RadioFormItemComponent from "./form/items/radio";
import EmailFormItemComponent from "./form/items/email";
import { useIsUserExists } from "../hooks/useIsUserExists";
import { useParams } from "react-router-dom";
import { useResultsShare } from "../hooks/useResultsShare";
import { useAccessTokenObj } from "../utils/jwt";
import { DEFAULT_ERROR_LABEL } from "../utils";
import FooterActions from "./FooterActions";

const ResultsShare: FC = () => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Button type={"primary"} onClick={() => setOpen(true)}>
        <Translate value={"share"} />
        <ShareAltOutlined />
      </Button>
      <ShareModal open={open} onClose={() => setOpen(false)} />
    </div>
  );
};

export default ResultsShare;

export enum ShareOptionsEnum {
  ONLY_FOR_REGISTERED_USERS = "onlyForRegisteredUsers",
  ALL_WHO_HAS_LINK = "allWhoHasLink",
  ALL_WHO_HAS_LINK_AND_PASSWORD = "allWhoHasLinkAndPassword",
}

const AccessOptions = () => [
  { value: ShareOptionsEnum.ONLY_FOR_REGISTERED_USERS, label: I18n.t("onlyForRegisteredUsers") },
  { value: ShareOptionsEnum.ALL_WHO_HAS_LINK, label: I18n.t("allWhoHasLink") },
  { value: ShareOptionsEnum.ALL_WHO_HAS_LINK_AND_PASSWORD, label: I18n.t("allWhoHasLinkAndPassword") },
];

type FormDataType = { sharedToEmail: string; accessPassword?: string; accessOption: string };

const ShareModal: FC<{ open: boolean; onClose: () => void }> = (props) => {
  const { id: surveyId } = useParams();
  const { open, onClose } = props;
  const [form] = Form.useForm<FormDataType>();
  const { isUserExist } = useIsUserExists();
  const { shareResult } = useResultsShare();
  const tokenObj = useAccessTokenObj();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(() => {
    const userId = tokenObj?.sub;
    if (!surveyId || !userId) return;
    form
      .validateFields()
      .then(async (result) => {
        setLoading(true);
        if (result.accessOption === ShareOptionsEnum.ONLY_FOR_REGISTERED_USERS) {
          const exist = await isUserExist(result.sharedToEmail);
          if (!exist) {
            message.error(I18n.t("userNotFound"));
            return;
          }
        }
        await shareResult(result, surveyId, userId);
        message.success(I18n.t("linkSent"));
        handleCancel();
      })
      .catch((e) => {
        if (e.name === "AxiosError") {
          message.error(I18n.t(DEFAULT_ERROR_LABEL)).then();
        }
        console.log(e);
      })
      .finally(() => setLoading(false));
  }, [form, surveyId, tokenObj?.sub]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    onClose();
    setLoading(false);
  }, [form]);

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      title={I18n.t(`shareSurveyResults`)}
      footer={
        <FooterActions
          loading={loading}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          cancelLabelTranslationKey={"cancel"}
          submitLabelTranslationKey={"sendLink"}
        />
      }
    >
      <Form form={form} layout={"vertical"} style={{ marginTop: 20 }}>
        <FormItems />
      </Form>
    </Modal>
  );
};

const FormItems = () => {
  return (
    <>
      <RadioFormItemComponent
        required={false}
        buttonType={"radio"}
        name={"accessOption"}
        direction={"vertical"}
        values={AccessOptions()}
        label={I18n.t("accessOptions") + ":"}
        initialValue={ShareOptionsEnum.ONLY_FOR_REGISTERED_USERS}
      />
      <EmailFormItemComponent
        addonBefore={null}
        name={"sharedToEmail"}
        label={I18n.t("enterEmail")}
        placeholder={I18n.t("enterEmail")}
      />
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const option = getFieldValue("accessOption");

          if (option === "allWhoHasLinkAndPassword") {
            return (
              <InputFormItemComponent
                name={"accessPassword"}
                label={I18n.t("enterPassword")}
                placeHolder={I18n.t("enterPassword")}
              />
            );
          }

          return null;
        }}
      </Form.Item>
    </>
  );
};
