import React, { FC, useCallback, useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import {
  DistributionChannel,
  DistributionChannelInfos,
  DistributionChannelTypeEnum,
} from "../../../../../types/DistributionChannel";
import { I18n } from "react-redux-i18n";
import dayjs from "dayjs";
import TableActions from "./components/TableActions";
import { CloseCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../../store";
import { useActions } from "../../../../../hooks/useActions";
import { Popconfirm, Table, Tooltip } from "antd";
import { useParams } from "react-router-dom";
import {
  deleteDistributionChannelById,
  getDistributionChannelsBySurveyId,
  setCurrentDistributionChannel,
} from "../../../../../store/slices/distributionChannels/distributionChannelsService";
import ChannelEditModal from "./components/getEditModalByChannelType";
import { setCurrentQrCode } from "../../../../../store/slices/qrCodes/qrCodesService";
import {
  changeDistributionStatus,
  setCurrentDistribution,
} from "../../../../../store/slices/distributions/distributionsService";
import { renderNullableText } from "../../../../../utils/renderNullableText";
import { convertDateToCurrentTimezone } from "../../../../../utils/timezones";
import { DistributionStatusAction } from "../../../../../utils/getDistributionStatusByAction";
import { DistributionStatus } from "../../../../../types/distributionType";

const getIconByChannelType = (channelType: DistributionChannelTypeEnum) => {
  return DistributionChannelInfos[channelType].icon;
};

const getLabelByChannelType = (channelType: DistributionChannelTypeEnum) => {
  return DistributionChannelInfos[channelType].label;
};

export const getColumns = (
  surveyId: string,
  setCurrentChannel: (channel: DistributionChannel) => void,
): ColumnsType<DistributionChannel> => {
  const dispatch = store.dispatch;

  const handleDelete = (channelId: number) => {
    dispatch(
      deleteDistributionChannelById({
        channelId,
        surveyId: surveyId,
      }),
    );
  };

  const handleEdit = (channel: DistributionChannel) => {
    setCurrentChannel(channel);
    if (
      (channel.channelType === DistributionChannelTypeEnum.QR ||
        channel.channelType === DistributionChannelTypeEnum.URL_QR) &&
      !!channel?.qrCode
    ) {
      dispatch(setCurrentQrCode(channel.qrCode));
    } else if (
      (channel.channelType === DistributionChannelTypeEnum.MSISDN ||
        channel.channelType === DistributionChannelTypeEnum.EMAIL) &&
      !!channel?.distribution
    ) {
      dispatch(setCurrentDistribution(channel.distribution));
    }
    dispatch(setCurrentDistributionChannel(channel));
  };

  const handleStart = (distributionId: string) => {
    dispatch(
      changeDistributionStatus({
        surveyId,
        distributionId,
        status: DistributionStatusAction.START,
      }),
    ).then(() => {
      dispatch(getDistributionChannelsBySurveyId(surveyId));
    });
  };

  const handleStop = (distributionId: string) => {
    dispatch(
      changeDistributionStatus({
        surveyId,
        distributionId,
        status: DistributionStatusAction.FINISH,
      }),
    ).then(() => {
      dispatch(getDistributionChannelsBySurveyId(surveyId));
    });
  };

  return [
    {
      key: "icon",
      width: 10,
      render: (record: DistributionChannel) => getIconByChannelType(record.channelType),
    },
    {
      title: I18n.t("channelName"),
      key: "channelType",
      render: (record: DistributionChannel) => <span>{getLabelByChannelType(record.channelType)}</span>,
    },
    {
      title: I18n.t("description"),
      key: "description",
      dataIndex: "description",
      render: renderNullableText,
    },
    {
      title: I18n.t("createDate"),
      key: "createdAt",
      dataIndex: "createdAt",
      render: (value: string) => {
        const convertedDate = convertDateToCurrentTimezone(value);
        return dayjs(convertedDate).format("D MMMM YYYY HH:mm");
      },
    },
    {
      title: I18n.t("actions"),
      key: "actions",
      width: 150,
      render: (record: DistributionChannel) => {
        const EditOrEyeIcon = record.channelType === DistributionChannelTypeEnum.URL ? EyeOutlined : EditOutlined;
        return (
          <div style={{ display: "flex", gap: 10 }}>
            {(record.distribution?.status !== DistributionStatus.FINISHED ||
              record.channelType === DistributionChannelTypeEnum.URL) && (
              <EditOrEyeIcon
                onClick={() => handleEdit(record)}
                style={{ fontSize: 17, marginLeft: 5, cursor: "pointer" }}
              />
            )}
            <TableActions data={record} />
            {record.channelType !== DistributionChannelTypeEnum.URL && (
              <Popconfirm
                title={I18n.t("distributionDeleteConfirmation")}
                onConfirm={() => handleDelete(record.channelId)}
                style={{ gap: 10 }}
              >
                <div style={{ gap: 10 }}>
                  <DeleteOutlined style={{ cursor: "pointer" }} />
                </div>
              </Popconfirm>
            )}
            {[DistributionChannelTypeEnum.EMAIL, DistributionChannelTypeEnum.MSISDN].includes(record.channelType) && (
              <>
                {record.distribution?.status === DistributionStatus.DRAFT && (
                  <Popconfirm
                    title={I18n.t("distributionStartConfirmation")}
                    onConfirm={() => {
                      if (record?.distribution?.distributionId) {
                        handleStart(record.distribution.distributionId);
                      } else {
                        console.error("distributionId is undefined");
                      }
                    }}
                  >
                    <Tooltip title={I18n.t("distributionStartConfirmationTooltip")}>
                      <PlayCircleOutlined style={{ color: "green", cursor: "pointer" }} />
                    </Tooltip>
                  </Popconfirm>
                )}
                {record.distribution?.status === DistributionStatus.ACTIVE && (
                  <Popconfirm
                    title={I18n.t("distributionFinishConfirmation")}
                    onConfirm={() => {
                      if (record?.distribution?.distributionId) {
                        handleStop(record.distribution.distributionId);
                      } else {
                        console.error("distributionId is undefined");
                      }
                    }}
                  >
                    <Tooltip title={I18n.t("distributionFinishConfirmationTooltip")}>
                      <CloseCircleOutlined style={{ color: "red", cursor: "pointer" }} />
                    </Tooltip>
                  </Popconfirm>
                )}
                {record.distribution?.status === DistributionStatus.PAUSED && (
                  <>
                    <Popconfirm
                      title={I18n.t("distributionStartConfirmation")}
                      onConfirm={() => {
                        if (record?.distribution?.distributionId) {
                          handleStart(record.distribution.distributionId);
                        } else {
                          console.error("distributionId is undefined");
                        }
                      }}
                    >
                      <Tooltip title={I18n.t("distributionStartConfirmationTooltip")}>
                        <PlayCircleOutlined style={{ color: "green", cursor: "pointer" }} />
                      </Tooltip>
                    </Popconfirm>
                    <Popconfirm
                      title={I18n.t("distributionFinishConfirmation")}
                      onConfirm={() => {
                        if (record?.distribution?.distributionId) {
                          handleStop(record.distribution.distributionId);
                        } else {
                          console.error("distributionId is undefined");
                        }
                      }}
                    >
                      <Tooltip title={I18n.t("distributionFinishConfirmationTooltip")}>
                        <CloseCircleOutlined style={{ color: "red", cursor: "pointer" }} />
                      </Tooltip>
                    </Popconfirm>
                  </>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];
};

const ShareChannelsTable: FC = () => {
  const { id: surveyId } = useParams();
  const { list, pageable } = useSelector((state: RootState) => state.distributionChannels);
  const { getDistributionChannelsBySurveyId, setDistributionChannelPagination } = useActions();
  const [currentChannel, setCurrentChannel] = useState<DistributionChannel | undefined>();
  const currentChannelStored = useSelector((state: RootState) => state.distributionChannels.current);

  const onPageChange = useCallback(
    (pageNumber: number, pageSize: number) => {
      setDistributionChannelPagination({ ...pageable, page: pageNumber - 1, size: pageSize });
    },
    [pageable],
  );

  useEffect(() => {
    if (!surveyId) return;
    getDistributionChannelsBySurveyId(surveyId);
  }, [surveyId, pageable.page]);

  useEffect(() => {
    if (!currentChannelStored && !!currentChannel) {
      setCurrentChannel(undefined);
    }
  }, [currentChannelStored]);

  if (!surveyId) {
    return <div />;
  }

  return (
    <>
      <Table
        columns={getColumns(surveyId, setCurrentChannel)}
        dataSource={list}
        scroll={{ x: 1000 }}
        pagination={{
          size: "small",
          onChange: onPageChange,
          total: pageable.total,
          pageSize: pageable.size,
          current: pageable.page + 1,
          defaultPageSize: pageable.size,
        }}
        rowKey={(record) => record?.channelId + ""}
      />
      {currentChannel && <ChannelEditModal type={currentChannel.channelType} />}
    </>
  );
};

export default ShareChannelsTable;
