import "./iphone.scss";
import React, { CSSProperties, FC, ReactNode, useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Progress,
  Radio,
  Select,
  Slider,
  Space,
  TimePicker,
  Upload,
} from "antd";
import { DownOutlined, PlusOutlined, SignalFilled, WifiOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import getInvertedColor from "../../utils/getInvertedColor";
import { I18n, Translate } from "react-redux-i18n";
import { langTitle } from "../../types/language";
import { getCurrentLangTextOrFirst } from "../../utils/getCurrentLangTextOrFirst";
import dayjs from "dayjs";
import { AnswerTemplateType, CsiScale } from "../../types/answerTemplateType";
import { TemplateEnum } from "../../types/templateEnum";
import { QuestionType } from "../../types/questionType";
import filterChoices from "../../utils/filterChoices";
import { sortByOrder } from "../../utils/sortByOrder";
import getChoiceTitle from "../../utils/getChoiceTitle";
import { getCurrentTextTranslation } from "../../utils/getCurrentTextTranslation";
import { getCriteriaAnswers } from "../../utils/criteriaAnswers";
import { getCriteriaOptions } from "../../utils/criteriaOptions";
import { NpsGradeEnum, NpsTypeEnum } from "../../types/npsInfoType";
import Rating from "../rating";
import { DecorFormType } from "../../pages/surveys/edit/steps/personalization/helpers";
import { QuestionMediaFileTypesEnum } from "../../types/AddMediaToQuestionResponseType";
import ButtonGroup from "antd/es/button/button-group";
import MarkdownParser from "../markdown";

type PhoneComponentProps = {
  readonly color: string;
  readonly logo: any;
  readonly style?: CSSProperties;
  readonly size?: "small" | "large";
  readonly form?: FormInstance<QuestionType>;
  readonly decorForm?: FormInstance<DecorFormType>;
  readonly redirectToAnotherSite?: boolean;
};

const PhoneComponent: FC<PhoneComponentProps> = (props) => {
  const { color, logo, style, size = "small", form, decorForm, redirectToAnotherSite } = props;
  const { surveyDecor, logoPath, mediaProps } = useSelector((state: RootState) => {
    return {
      logoPath: logo || state.surveys?.current?.logoPath,
      mediaProps: state.questions.current?.mediaProperties,
      surveyDecor: state.surveys.current?.surveyDecor,
    };
  });
  const language = useSelector((state: RootState) => state.persisted.appLanguage);
  const invertedColor = getInvertedColor(color);

  return (
    <NullableForm form={decorForm}>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const themeColor = getFieldValue("themeColor");
          const inverted = getInvertedColor(themeColor || color);
          const surveyDecorForm = form?.getFieldValue(["surveyDecor"]) ?? getFieldValue(["surveyDecor"]);

          return (
            <div
              className={`iphone-x phone__${size}`}
              style={
                {
                  "--app-color": themeColor || color,
                  "--inverted-color": inverted || invertedColor,
                  "--title-font": surveyDecorForm?.titleFont ?? surveyDecor?.titleFont,
                  "--text-font": surveyDecorForm?.textFont ?? surveyDecor?.textFont,
                  ...style,
                } as any
              }
            >
              <div className={"container unselectable"} style={{ background: "#ffffff", borderRadius: 33 }}>
                {size === "large" && (
                  <div className={"communications"}>
                    <span className={"clock"}>{dayjs(Date.now()).format("HH:mm")}</span>
                    <SignalFilled />
                    <WifiOutlined />
                    <span className={"battery"}>100</span>
                  </div>
                )}

                <div className={"header"}>
                  <img className={"logo"} src={logoPath} alt={""} />
                  <div
                    className={"lang-select"}
                    style={{ boxShadow: `${inverted || invertedColor} 0 0px 3px 0`, cursor: "pointer" }}
                  >
                    <span style={{ color: inverted || invertedColor }}>{langTitle[language]}</span>
                    <DownOutlined style={{ color: inverted || invertedColor, marginLeft: 5 }} />
                  </div>
                </div>
                {surveyDecorForm?.showProgress && (
                  <Progress
                    style={{
                      padding: "0px 5px",
                      background: "#ffffff",
                      position: "absolute",
                      top: 75,
                    }}
                    percent={100}
                    strokeWidth={13}
                    success={{
                      percent: 100,
                      strokeColor: "#52c41a",
                    }}
                  />
                )}
                <div
                  className={"phone-content-scrollable-container"}
                  style={{ overflow: "auto", display: "flex", flexDirection: "column", height: "100%" }}
                >
                  {mediaProps && (
                    <div
                      style={{
                        width: "100%",
                        padding: 10,
                        height: "auto",
                        marginBottom: 15,
                      }}
                    >
                      {mediaProps.contentType === QuestionMediaFileTypesEnum.VIDEO ? (
                        <video
                          controls
                          src={mediaProps.url}
                          autoPlay
                          style={{
                            width: "inherit",
                            borderRadius: 8,
                            height: "inherit",
                            objectFit: "contain",
                            objectPosition: "center",
                          }}
                        />
                      ) : (
                        <img
                          style={{
                            width: "100%",
                            borderRadius: 8,
                            height: "auto",
                            objectFit: "contain",
                            objectPosition: "center",
                          }}
                          src={mediaProps.url}
                          alt="media prview"
                        />
                      )}
                    </div>
                  )}
                  <div className={"texts"}>
                    <h3
                      style={{
                        color: surveyDecorForm?.titleColor ?? surveyDecor?.titleColor,
                        fontSize: surveyDecorForm?.titleSize ?? surveyDecor?.titleSize ?? 20,
                      }}
                      className={"dont-use-global-font"}
                    >
                      {surveyDecor?.title ? surveyDecor.title : ""}
                    </h3>
                    {!form ? (
                      <p
                        style={{
                          color: surveyDecorForm?.textColor ?? surveyDecor?.textColor,
                          fontSize: surveyDecorForm?.textSize ?? surveyDecor?.textSize ?? 16,
                          height: "auto",
                          whiteSpace: "pre-line",
                        }}
                      >
                        <Translate value={"text"} className={"dont-use-global-font"} />
                      </p>
                    ) : (
                      <Form.Item shouldUpdate noStyle>
                        {() => {
                          const values = form.getFieldValue("textTranslations");
                          return !redirectToAnotherSite ? (
                            <p
                              style={{
                                color: surveyDecorForm?.textColor ?? surveyDecor?.textColor,
                                fontSize: surveyDecorForm?.textSize ?? surveyDecor?.textSize ?? 16,
                                height: "auto",
                                whiteSpace: "pre-line",
                                lineHeight: 0,
                              }}
                            >
                              <MarkdownParser mixedString={getCurrentLangTextOrFirst(values)} />
                            </p>
                          ) : null;
                        }}
                      </Form.Item>
                    )}
                  </div>
                  {!form ? (
                    <div className={"button"}>
                      <Button type={"primary"} style={{ color: inverted || invertedColor, boxShadow: "none" }}>
                        <Translate value={"next"} />
                      </Button>
                    </div>
                  ) : (
                    <Form.Item shouldUpdate noStyle>
                      {() => {
                        const value = form?.getFieldValue("answerTemplate");
                        return <Content color={inverted || invertedColor} answerTemplate={value} form={form} />;
                      }}
                    </Form.Item>
                  )}
                </div>
              </div>
              {size === "large" && <span className={"phone-back-btn-line"} />}
            </div>
          );
        }}
      </Form.Item>
    </NullableForm>
  );
};

export default PhoneComponent;

const NullableForm: FC<{ form?: FormInstance<DecorFormType>; children: ReactNode }> = ({ form, children }) => {
  if (!form) {
    return <>{children}</>;
  }
  return <Form form={form}>{children}</Form>;
};

const Content: FC<{
  readonly answerTemplate: AnswerTemplateType;
  readonly color: string;
  readonly form?: FormInstance<QuestionType>;
}> = ({ answerTemplate, color, form }) => {
  const appLang = useSelector((state: RootState) => state.persisted.appLanguage);
  const isEndBlock = useSelector((state: RootState) => state.questions?.current?.endBlock);
  const [content, setContent] = useState<ReactNode>(<></>);
  const [activeButton, setActiveButton] = useState<number>(0);

  const handleClick = (index: number) => {
    setActiveButton(index);
  };

  const isType = useCallback(
    (type: TemplateEnum) => {
      return type === answerTemplate?.type;
    },
    [answerTemplate?.type],
  );

  useEffect(() => {
    if (isType(TemplateEnum.INFO_BLOCK) || isType(TemplateEnum.START_BLOCK) || isType(TemplateEnum.END_BLOCK)) {
      setContent(<></>);
    } else if (
      isType(TemplateEnum.IIN) ||
      isType(TemplateEnum.EMAIL) ||
      isType(TemplateEnum.TEXT) ||
      isType(TemplateEnum.NUMBER)
    ) {
      setContent(
        <>
          {answerTemplate?.customAnswerIsRequired && <span className={"required-mark"}>*</span>}
          <Input placeholder={I18n.t("yourAnswer")} variant={"borderless"} />
        </>,
      );
    } else if (isType(TemplateEnum.CSI)) {
      const csiParams = form?.getFieldValue(["answerTemplate", "csiParams"]);
      const criteria = form?.getFieldValue(["answerTemplate", "criteria"]);
      const csiScale = form?.getFieldValue(["answerTemplate", "csiScale"]) || CsiScale.TEN_POINTS;
      const answers = getCriteriaAnswers(csiParams, criteria, csiScale);

      setContent(
        <div className={"csi-template"}>
          {answers.map((csi: any, i) => {
            return (
              <div className={"csi-item"} key={i}>
                <h4>{csi.paramName}</h4>
                <div className={"criteria-container"}>
                  {csi.criterias?.map((criteria: any, j: number) => {
                    return (
                      <div className={"criteria-item"} key={j}>
                        <h5>{criteria.name}</h5>
                        <Select
                          value={csiScale === CsiScale.BOOLEAN ? "Да" : 4}
                          options={getCriteriaOptions(csiScale)}
                          className={"criteria-select"}
                          variant={"borderless"}
                          placeholder={"-----"}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>,
      );
    } else if (isType(TemplateEnum.NPS)) {
      const npsParams = answerTemplate?.npsInfoParams?.npsParams;
      const npsInfoParams = answerTemplate?.npsInfoParams;
      const maxGrade = Number(npsInfoParams?.maxGrade || 5);

      setContent(
        <div className={"nps-template"}>
          <div style={{ marginBottom: 20 }}>
            {npsParams
              ?.map((it) => it)
              ?.sort((it) => (it?.grade === NpsGradeEnum.MAX_GRADE ? 1 : -1))
              ?.map((it) => (
                <div key={it.grade} className={"grade-text"}>
                  <span>
                    <Translate value={it?.grade === NpsGradeEnum.MAX_GRADE ? "max" : "min"} />
                  </span>
                  <span>{getCurrentTextTranslation(it?.textTranslations)}</span>
                </div>
              ))}
          </div>
          {npsInfoParams?.type === NpsTypeEnum.SLIDER ? (
            <div>
              <div className={"slider-container"}>
                <Slider dots min={1} max={maxGrade} defaultValue={4} tooltip={{ open: true }} />
              </div>
              <div className={"lowest-highest-text-container"}>
                <span className={"lowest-text"}>{(answerTemplate?.lowestScoreLabel ?? "").slice(0, 25)}</span>
                <span className={"highest-text"}>{(answerTemplate?.highestScoreLabel ?? "").slice(0, 25)}</span>
              </div>
            </div>
          ) : npsInfoParams?.type === NpsTypeEnum.RATING ? (
            <div>
              <div className={"rating-container"}>
                <Rating defaultValue={5} maxValue={maxGrade} iconType={npsInfoParams?.icon} />
              </div>
              <div className={"lowest-highest-text-container"}>
                <span className={"lowest-text"}>{(answerTemplate?.lowestScoreLabel ?? "").slice(0, 25)}</span>
                <span className={"highest-text"}>{(answerTemplate?.highestScoreLabel ?? "").slice(0, 25)}</span>
              </div>
            </div>
          ) : npsInfoParams?.type === NpsTypeEnum.DROPDOWN ? (
            <Select defaultValue={4} style={{ width: "98%" }}>
              {Array.from(Array(maxGrade).keys())
                .reverse()
                .map((it, index) => (
                  <Select.Option value={++it} key={it}>
                    {it}
                    <span style={{ marginLeft: 15, fontSize: 10 }}>
                      {index === 0 ? (answerTemplate?.highestScoreLabel ?? "").slice(0, 20) : ""}
                    </span>
                    <span style={{ fontSize: 10 }}>
                      {index === maxGrade - 1 ? (answerTemplate?.lowestScoreLabel ?? "").slice(0, 20) : ""}
                    </span>
                  </Select.Option>
                ))}
            </Select>
          ) : npsInfoParams?.type === NpsTypeEnum.RADIO_BUTTONS ? (
            <div style={{ display: "flex", height: "100px" }}>
              <Radio.Group>
                <Space direction="vertical">
                  {Array.from(Array(maxGrade).keys())
                    .reverse()
                    .map((it, index) => (
                      <Radio value={++it} key={it}>
                        <span style={{ marginLeft: 10 }}>{it}</span>
                        <span style={{ marginLeft: 15, fontSize: 10 }}>
                          {index === 0 ? (answerTemplate?.highestScoreLabel ?? "").slice(0, 20) : ""}
                        </span>
                        <span style={{ fontSize: 10 }}>
                          {index === maxGrade - 1 ? (answerTemplate?.lowestScoreLabel ?? "").slice(0, 20) : ""}
                        </span>
                      </Radio>
                    ))}
                </Space>
              </Radio.Group>
            </div>
          ) : (
            <></>
          )}
        </div>,
      );
    } else if (isType(TemplateEnum.LOYALTY_INDEX)) {
      const loyaltyInfoParams = answerTemplate?.loyaltyIndexType;
      const maxGrade = Number(loyaltyInfoParams?.maxGrade || 10);
      setContent(
        <div>
          <ButtonGroup className="loyalty-button-group">
            {Array.from(Array(maxGrade + 1).keys()).map((number) => (
              <Button
                key={number}
                className="loyalty-button"
                type={activeButton === number ? "primary" : "default"}
                onClick={() => handleClick(number)}
              >
                {number}
              </Button>
            ))}
          </ButtonGroup>
          <div className={"lowest-highest-text-container"}>
            <span className={"lowest-text"}>{(answerTemplate?.lowestScoreLabel ?? "").slice(0, 25)}</span>
            <span className={"highest-text"}>{(answerTemplate?.highestScoreLabel ?? "").slice(0, 25)}</span>
          </div>
        </div>,
      );
    } else if (isType(TemplateEnum.FILE_UPLOAD)) {
      setContent(
        <div>
          <Input
            placeholder={I18n.t("yourAnswer")}
            style={{ marginBottom: 10 }}
            variant={"borderless"}
            size={"small"}
          />
          <Upload disabled>
            <Button icon={<PlusOutlined />} size={"small"}>
              <Translate value={"selectFile"} />
            </Button>
          </Upload>
        </div>,
      );
    } else if (isType(TemplateEnum.SINGLE_CHOICE) || isType(TemplateEnum.MULTIPLE_CHOICE)) {
      const options = answerTemplate?.answerChoices
        ?.filter((ac) => ac)
        ?.sort(sortByOrder)
        ?.map(getChoiceTitle);

      setContent(
        <div>
          {isType(TemplateEnum.SINGLE_CHOICE) ? (
            <Radio.Group style={{ display: "grid" }}>
              {filterChoices(options)?.map((option, i) => (
                <Radio key={i} value={option} style={{ marginBottom: 10 }}>
                  <span style={{ position: "relative", left: 5 }}>{option}</span>
                </Radio>
              ))}
            </Radio.Group>
          ) : (
            <Checkbox.Group className={"phone-answer-template-checkbox"} options={filterChoices(options)} />
          )}

          {answerTemplate?.addCustomAnswer && (
            <div style={{ marginTop: 24 }}>
              {getCurrentTextTranslation(answerTemplate?.textTranslations)}
              <Input variant={"borderless"} placeholder={I18n.t("yourAnswer")} />
            </div>
          )}
        </div>,
      );
    } else if (isType(TemplateEnum.DATE)) {
      setContent(
        <div>
          {answerTemplate?.customAnswerIsRequired && <span className={"required-mark"}>*</span>}
          <DatePicker size={"small"} variant={"filled"} style={{ width: "100%" }} />
        </div>,
      );
    } else if (isType(TemplateEnum.DATETIME)) {
      setContent(
        <div>
          {answerTemplate?.customAnswerIsRequired && <span className={"required-mark"}>*</span>}
          <div style={{ display: "flex" }}>
            <DatePicker variant={"filled"} size={"small"} />
            <TimePicker variant={"filled"} size={"small"} showSecond={false} />
          </div>
        </div>,
      );
    } else {
      setContent(
        <div className={"content"}>
          <div className={"item"}>
            <Checkbox checked={true}>
              <Translate value={"answerVariable"} />
            </Checkbox>
          </div>
          <div className={"item"}>
            <Radio checked={true}>
              <Translate value={"answerVariable"} />
            </Radio>
          </div>
        </div>,
      );
    }
  }, [answerTemplate, appLang]);

  const actionLabel = form?.getFieldValue(["answerTemplate", "actionLabel"]);

  return isEndBlock ? (
    <div style={{ padding: 20 }}>
      <Translate value={"closeTabText"} />
    </div>
  ) : (
    <>
      <div className={"phone-answer-template-content"}>{content}</div>
      <div className={"button-container"} style={{ marginTop: "auto" }}>
        <Button type={"primary"} style={{ width: 232, marginBottom: 20 }}>
          <span>{actionLabel ?? <Translate value={"next"} style={{ color }} />}</span>
        </Button>
      </div>
    </>
  );
};
