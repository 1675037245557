import React, { FC, useCallback, useEffect, useState } from "react";
import IconButton from "../iconButton";
import { SocialMedia } from "../../../../../../../components/icons";
import { Button, Dropdown, Form, Space } from "antd";
import { DistributionChannelTypeEnum, SocialMedium } from "../../../../../../../types/DistributionChannel";
import InputFormItemComponent from "../../../../../../../components/form/items/input";
import { I18n, Translate } from "react-redux-i18n";
import BaseCreateModal from "../baseCreateModal";
import { useActions } from "../../../../../../../hooks/useActions";
import { useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../../../../../utils/capitalizeFirstLetter";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import { useDistributionChannelsApi } from "../../../../../../../hooks/useDistributionChannelsApi";
import { SocialMediaButtonWithShareAction } from "../../../../../../../components/socialMediumShare";
import { Language } from "../../../../../../../types/language";
import { getSurveyInstanceUrl } from "../../../distributionAndNotification/qrCodes/helpers";

const getSubDescription = (lang: Language, surveyName: string, url: string) => {
  const messageRu = `Здравствуйте! \nВас приглашают пройти опрос - ${surveyName} \n${url}`;
  const messageEn = `Hello! \nYou are invited to take a survey - ${surveyName} \n${url}`;
  const messageKz = `Сәлеметсіз бе! \nСізді - ${surveyName} - сауалнамаға қатысуға шақырады \n${url}`;

  if (lang === Language.EN) {
    return messageEn;
  } else if (lang === Language.KK) {
    return messageKz;
  }
  return messageRu;
};

type Props = {
  readonly showButton?: boolean;
  readonly type?: DistributionChannelTypeEnum;
  readonly openModal?: boolean;
};

const getDefaultProps = (lang: Language, surveyName: string, url: string) => ({
  initialValues: {
    subDescription: getSubDescription(lang, surveyName, url),
  },
  showButton: true,
  openModal: false,
});

const SocialMediaButton: FC<Props> = (props) => {
  const { id: surveyId } = useParams();
  const { surveyName, appLang } = useSelector((state: RootState) => {
    return {
      surveyName: state.surveys?.current?.surveyName ?? "",
      appLang: state.persisted.appLanguage,
    };
  });
  const { initialValues, showButton, openModal } = {
    ...getDefaultProps(appLang, surveyName, getSurveyInstanceUrl(surveyId ?? "", "social-media", props.type ?? "")),
    ...props,
  };
  const [modalOpen, setModalOpen] = useState(openModal ?? false);
  const [form] = Form.useForm<{ description: string; subDescription: string }>();
  const [currentSM, setCurrentSM] = useState<SocialMedium | undefined>();
  const [loading, setLoading] = useState(false);
  const { setCurrentDistributionChannel } = useActions();
  const { createDistributionChannel, updateDistributionChannel } = useDistributionChannelsApi();
  const currentChannel = useSelector((state: RootState) => state.distributionChannels.current);
  const [subDescription, setSubDescription] = useState<string | undefined>();

  const handleSMClicked = useCallback(
    (socialMedia: SocialMedium) => {
      setModalOpen(true);
      setCurrentSM(socialMedia);
    },
    [setModalOpen, setCurrentSM],
  );

  const handleCancel = useCallback(() => {
    setModalOpen(false);
    setCurrentSM(undefined);
    setCurrentDistributionChannel(undefined);
    form.resetFields();
  }, [setModalOpen, setCurrentSM, setCurrentDistributionChannel, form]);

  const handleSubmit = useCallback(() => {
    if (!surveyId) return;
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        if (currentChannel?.channelId) {
          await updateDistributionChannel({
            channelId: currentChannel.channelId,
            description: values.description,
          });
        } else {
          await createDistributionChannel({
            channelType: currentSM as unknown as DistributionChannelTypeEnum,
            description: values.description,
            surveyId: surveyId,
          });
          handleCancel();
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [surveyId, currentSM, form, handleCancel, currentChannel, updateDistributionChannel, createDistributionChannel]);

  useEffect(() => {
    const addOnlyUrl = currentSM === SocialMedium.FACEBOOK || currentSM === SocialMedium.LINKEDIN;
    const surveyInstanceUrl = getSurveyInstanceUrl(surveyId ?? "", currentSM ?? "", currentSM ?? "");
    const newSubDescription = addOnlyUrl
      ? surveyInstanceUrl
      : getSubDescription(appLang, surveyName, surveyInstanceUrl);

    setSubDescription(newSubDescription);
    form.setFieldValue("description", I18n.t("shareOn").concat(`${capitalizeFirstLetter(currentSM ?? "")}`));
    form.setFieldValue("subDescription", newSubDescription);
  }, [currentSM]);

  useEffect(() => {
    if (currentChannel?.channelType) {
      setCurrentSM(currentChannel.channelType as unknown as SocialMedium);
    }
    if (currentChannel?.description) {
      form.setFieldValue("description", currentChannel?.description);
    }
  }, []);

  return (
    <>
      {showButton && (
        <Dropdown
          trigger={["click"]}
          overlayStyle={{ display: "flex", justifyContent: "center" }}
          destroyPopupOnHide
          menu={{
            items: [
              {
                key: "linkedin",
                label: <img style={{ width: 30 }} src={`/assets/icons/linkedin.png`} alt={"linkedin"} />,
                onClick: () => handleSMClicked(SocialMedium.LINKEDIN),
              },
              {
                key: "facebook",
                label: <img style={{ width: 30 }} src={`/assets/icons/facebook.png`} alt={"facebook"} />,
                onClick: () => handleSMClicked(SocialMedium.FACEBOOK),
              },
              {
                key: "telegram",
                label: <img style={{ width: 30 }} src={`/assets/icons/telegram.png`} alt={"telegram"} />,
                onClick: () => handleSMClicked(SocialMedium.TELEGRAM),
              },
              {
                key: "whatsapp",
                label: <img style={{ width: 30 }} src={`/assets/icons/whatsapp.png`} alt={"whatsapp"} />,
                onClick: () => handleSMClicked(SocialMedium.WHATSAPP),
              },
              {
                key: "mail",
                label: <img style={{ width: 30 }} src={`/assets/icons/mail.png`} alt={"mail"} />,
                onClick: () => handleSMClicked(SocialMedium.EMAIL),
              },
              {
                key: "twitter",
                label: <img style={{ width: 30 }} src={`/assets/icons/twitter.png`} alt={"twitter"} />,
                onClick: () => handleSMClicked(SocialMedium.TWITTER),
              },
            ],
          }}
        >
          <Space>
            <IconButton icon={<SocialMedia />} label={I18n.t("socialMedia")} onClick={() => {}} />
          </Space>
        </Dropdown>
      )}
      <BaseCreateModal
        children={
          <Form style={{ marginTop: 24 }} layout={"vertical"} form={form} initialValues={initialValues}>
            <InputFormItemComponent required name={"description"} label={I18n.t("description")} />
            <InputFormItemComponent showTextArea name={"subDescription"} onChange={setSubDescription} />
          </Form>
        }
        footer={
          <div
            style={{
              gap: 10,
              width: "100%",
              marginTop: 30,
              display: "flex",
              marginBottom: 14,
              justifyContent: "center",
            }}
          >
            <Button onClick={handleCancel} style={{ flex: 1 }}>
              <Translate value={"cancel"} />
            </Button>
            <SocialMediaButtonWithShareAction
              style={{ flex: 1 }}
              onClick={handleSubmit}
              socialMedia={currentSM}
              message={subDescription ?? currentSM ?? ""}
            />
          </div>
        }
        onSubmit={() => {}}
        onCancel={handleCancel}
        loading={loading}
        open={modalOpen}
      />
    </>
  );
};

export default SocialMediaButton;
