import { QuestionType } from "../types/questionType";
import { TemplateEnum } from "../types/templateEnum";
import { isURL } from "../utils/renderNullableText";

export const prepareQuestion = (question: QuestionType) => {
  const prevAnswerCondition = question.previousAnswerCondition;

  if (prevAnswerCondition) {
    const allFieldsUndefined =
      prevAnswerCondition.answeredQuestionId === undefined &&
      prevAnswerCondition.operator === undefined &&
      prevAnswerCondition.value === undefined;

    if (allFieldsUndefined) {
      question = { ...question, previousAnswerCondition: undefined };
    }
  }

  if (question.answerTemplate?.type === TemplateEnum.END_BLOCK && isURL(question?.redirectPath ?? "")) {
    return {
      ...question,
      textTranslations: question.textTranslations?.map((tt) => ({
        ...tt,
        text: tt?.text ?? "",
      })),
    };
  }
  return question;
};
