import "./index.scss";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Form, message, Tabs, Tooltip } from "antd";
import InputFormItemComponent from "../../../../../components/form/items/input";
import { useActions } from "../../../../../hooks/useActions";
import { APP_PRIMARY_COLOR, DEFAULT_ANSWER_TEMPLATE, DEFAULT_FIRST_SMS } from "../../../../../utils";
import { QuestionType } from "../../../../../types/questionType";
import { ChannelEnum } from "../../../../../types/channelEnum";
import { getTemplateLabels, templateComponents, TemplateEnum, templateIcons } from "../../../../../types/templateEnum";
import HiddenValueFormItemComponent from "../../../../../components/form/items/hiddenValue";
import TagsFormItemComponent from "../../../../../components/form/items/tagsInput";
import { I18n, Translate } from "react-redux-i18n";
import { ExclamationCircleOutlined, SettingOutlined } from "@ant-design/icons";
import SelectFormItemComponent from "../../../../../components/form/items/select";
import PhoneComponent from "../../../../../components/phone";
import { useFormDirtyState } from "./editPageLayout";
import QuestionViewSettingsModal from "./questionViewSettingsModal";
import { mapTextToSurveyUrl, useQuestionEditPageSelectorsState } from "./helpers";
import { isEndBlock } from "../../../../../utils/isEndBlock";
import { useRequests } from "../../../../../hooks/useRequests";
import isWelcome from "../../../../../utils/welcomeSmsChecker";
import { isWelcomePageBlock } from "../../../../../utils/isWelcomePageBlock";
import SurveyEmulator from "./emulator";
import MediaUpload from "./mediaUpload";
import { isURL } from "../../../../../utils/renderNullableText";
import { useTourCtx } from "../../../../../hooks/providers/tours/TourProvider";
import { CreateSurveyQuestionsTourItemsEnum, TourItemsEnum } from "../../../../../types/tour";
import useSurveyDisabled from "../../../../../hooks/useSurveyDisabled";
import { SurveyLanguage } from "../../../../../types/language";
import MdxEditor from "../../../../../components/mdxEditor";

type Props = {
  readonly formDirtyState: ReturnType<typeof useFormDirtyState>;
  readonly changeEditPageVisible: () => void;
};

const QuestionEditPage: FC<Props> = (props) => {
  const {
    data: {
      [TourItemsEnum.CREATE_SURVEY_QUESTIONS]: { refs },
    },
  } = useTourCtx();

  const { formDirtyState, changeEditPageVisible } = props;
  const disabled = useSurveyDisabled();
  const [form] = Form.useForm<QuestionType>();
  const { reorderQuestions, setCurrentQuestion } = useActions();
  const { createQuestion: createQuestionAsync, updateQuestion: updateQuestionAsync } = useRequests();
  const { surveyId, question, questions, channel, surveyColor, languages } = useQuestionEditPageSelectorsState();

  const [templateType, setTemplateType] = useState<TemplateEnum | undefined>();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [withPreviousAnswer, setWithPreviousAnswer] = useState(false);
  const [curTabLang, setCurTabLang] = useState<{ lang: SurveyLanguage; index: number } | undefined>();
  const [redirectToAnotherSite, setRedirectToAnotherSite] = useState(false);

  const isChannel = useCallback((c: ChannelEnum) => channel === c, [channel]);

  const onFinish = useCallback(async () => {
    if (!surveyId) return;
    form
      .validateFields()
      .then(async (values) => {
        if (!redirectToAnotherSite) {
          delete values.redirectPath;
        }

        if (values?.answerTemplate?.maxOptionLimit) {
          values.answerTemplate.maxOptionLimit = Number(values.answerTemplate.maxOptionLimit);
        }

        values = {
          ...values,
          tags: question?.tags || [],
          conditions: question?.conditions || [],
          previousAnswerCondition: values?.previousAnswerCondition
            ? {
                ...values.previousAnswerCondition,
                value: !Array.isArray(values.previousAnswerCondition?.value)
                  ? values.previousAnswerCondition?.value
                  : values.previousAnswerCondition?.value.join(","),
              }
            : undefined,
          withPreviousAnswer: undefined,
        };

        let newQuestion: QuestionType | undefined = undefined;

        if (!values?.questionId) {
          newQuestion = await createQuestionAsync(surveyId, values);
          message.success(I18n.t("questionCreated"));
        } else {
          await updateQuestionAsync(surveyId, values);
        }

        const isWebChannel = isChannel(ChannelEnum.WEB);
        const shouldReorder = values?.answerTemplate?.type === TemplateEnum.START_BLOCK;

        if (isWebChannel && shouldReorder) {
          const welcomeQuestion = questions.find(isWelcome) as QuestionType;
          const welcomePage = (
            values?.answerTemplate?.type === TemplateEnum.START_BLOCK ? newQuestion : questions.find(isWelcomePageBlock)
          ) as QuestionType;
          const endBlockQuestions = [
            ...questions.filter((q) => isEndBlock(q)),
            values?.answerTemplate?.type === TemplateEnum.END_BLOCK ? newQuestion : undefined,
          ].filter(Boolean) as QuestionType[];
          const otherQuestions = questions.filter((q) => !isWelcome(q) && !isEndBlock(q) && !isWelcomePageBlock(q));
          const questionsToReorder = [welcomeQuestion, welcomePage, ...otherQuestions, ...endBlockQuestions];

          reorderQuestions({ surveyId, questions: questionsToReorder });
        }
        formDirtyState.setFormDirty(false);
      })
      .catch(console.log);
  }, [surveyId, form, question, questions, isChannel, isWelcome, formDirtyState, redirectToAnotherSite]);

  const onCancel = useCallback(() => {
    changeEditPageVisible();
    form.resetFields();
    setCurrentQuestion(undefined);
  }, [form, setCurrentQuestion, changeEditPageVisible]);

  const handleCloseSettingsModal = useCallback(
    (forceReset = false) => {
      const prevAnswerCondition = form.getFieldValue("previousAnswerCondition");
      function reset$() {
        form.setFieldValue("withPreviousAnswer", false);
        form.setFieldValue("previousAnswerCondition", undefined);
      }
      if (
        withPreviousAnswer &&
        (!prevAnswerCondition ||
          !prevAnswerCondition?.answeredQuestionId ||
          !prevAnswerCondition?.operator ||
          !prevAnswerCondition?.value)
      ) {
        setWithPreviousAnswer(false);
        reset$();
      }
      const conditionId = questions.find((it) => it.questionId === question?.questionId)?.previousAnswerCondition
        ?.conditionId;
      if (forceReset && !conditionId) {
        reset$();
      }
      setShowSettingsModal(false);
    },
    [question, questions, form, withPreviousAnswer, setWithPreviousAnswer, setShowSettingsModal],
  );

  const handleUpdateWithPreviousAnswer = (value: boolean) => {
    setWithPreviousAnswer(value);
  };

  const hasUnfilledFields = useCallback((langCode: string, fields: QuestionType) => {
    if (fields.answerTemplate?.type === TemplateEnum.END_BLOCK && isURL(fields?.redirectPath ?? "")) {
      return false;
    }

    const hasQuestionName = Boolean(fields.textTranslations?.find((it) => it?.language.langCode === langCode)?.text);

    if (!hasQuestionName) {
      return true;
    }
    if (
      fields.answerTemplate?.type === TemplateEnum.MULTIPLE_CHOICE ||
      fields.answerTemplate?.type === TemplateEnum.SINGLE_CHOICE
    ) {
      if (!fields.answerTemplate.answerChoices || !fields.answerTemplate.answerChoices.length) {
        return true;
      } else {
        for (const answerChoice of fields.answerTemplate.answerChoices) {
          const hasChoiceText = Boolean(
            answerChoice.textTranslations?.find((it) => it?.language.langCode === langCode)?.text,
          );
          if (!hasChoiceText) {
            return true;
          }
        }
      }
      return false;
    } else if (fields.answerTemplate?.type === TemplateEnum.NPS) {
      return !fields.answerTemplate.npsInfoParams?.type || !fields.answerTemplate.npsInfoParams?.maxGrade;
    } else if (fields.answerTemplate?.type === TemplateEnum.CSI) {
      if (
        !fields.answerTemplate.csiScale ||
        !fields.answerTemplate.csiParams.length ||
        !fields.answerTemplate.criteria.length
      ) {
        return true;
      }

      if (
        fields.answerTemplate.csiParams.find(
          (it) => !Boolean(it.textTranslations?.find((tt) => tt?.language.langCode === langCode)?.text),
        ) ||
        fields.answerTemplate.criteria.find(
          (it) => !Boolean(it.textTranslations?.find((tt) => tt?.language.langCode === langCode)?.text),
        )
      ) {
        return true;
      }
    }
    return false;
  }, []);

  const onValuesChange = useCallback(
    (value: Record<string, any>) => {
      if (typeof value?.withPreviousAnswer === "boolean") {
        form.resetFields(["previousAnswerCondition"]);
        setWithPreviousAnswer(value.withPreviousAnswer);
      }
      if (!formDirtyState.isFormDirty) {
        formDirtyState.setFormDirty(true);
      }
      const templateType$ = value?.answerTemplate?.type as TemplateEnum | undefined;

      if (templateType$) {
        setTemplateType(templateType$);
        const templateLabel = getTemplateLabels()[templateType$];
        const sameTemplatesQuantity = questions.filter(
          (q) => q.answerTemplate?.type === templateType$ && q.name.startsWith(templateLabel),
        ).length;
        const qName = templateLabel + (sameTemplatesQuantity > 0 ? ` (${sameTemplatesQuantity})` : "");

        form.setFieldsValue({
          startBlock: templateType$ === TemplateEnum.START_BLOCK,
          endBlock: templateType$ === TemplateEnum.END_BLOCK,
          name: qName,
        });
        if (templateType$ !== TemplateEnum.SINGLE_CHOICE && templateType$ !== TemplateEnum.MULTIPLE_CHOICE) {
          form.setFieldsValue({
            answerTemplate: { ...DEFAULT_ANSWER_TEMPLATE, type: templateType$ },
          });
        }
      }
    },
    [questions, formDirtyState, form],
  );

  useEffect(() => {
    form.resetFields();

    if (isChannel(ChannelEnum.WEB) && !questions?.length) {
      const sms = {
        ...DEFAULT_FIRST_SMS,
        textTranslations: languages.map(mapTextToSurveyUrl),
      };

      form.setFieldsValue(sms);
      setCurrentQuestion(sms);
    } else if (!!question) {
      setTemplateType(question?.answerTemplate?.type);

      form.setFieldsValue({
        ...question,
        answerTemplate: {
          ...question.answerTemplate,
          actionLabel: question?.answerTemplate?.actionLabel ?? "Далее",
        },
        withPreviousAnswer: !!question?.previousAnswerCondition?.conditionId,
      });

      setWithPreviousAnswer(!!question?.previousAnswerCondition?.conditionId);
    }
  }, [question?.order]);

  useEffect(() => {
    if (question?.questionId) {
      form.setFieldValue("questionId", question.questionId);
    }
    if (isURL(question?.redirectPath ?? "")) {
      setRedirectToAnotherSite(true);
    }
  }, [question?.questionId]);

  useEffect(() => {
    const firstLang = languages.at(0);

    if (!curTabLang && firstLang) {
      setCurTabLang({ lang: firstLang, index: 0 });
    }
  }, [curTabLang, languages]);

  const handleRedirectToAnotherSiteChange = useCallback(
    (newValue: boolean) => {
      setRedirectToAnotherSite(newValue);
      if (!newValue) {
        form.setFields([{ name: "redirectPath", value: "", errors: [] }]);
      }
    },
    [form, setRedirectToAnotherSite],
  );
  return (
    <div className={"questions-edit-page"}>
      <div className={"form-container"}>
        <Form
          form={form}
          scrollToFirstError
          disabled={disabled}
          onFinish={onFinish}
          layout={"vertical"}
          onValuesChange={onValuesChange}
          onFinishFailed={() => message.error(I18n.t("fillRequiredFields"))}
        >
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={"question-configurations-container"}>
                <HiddenItems />

                <div ref={refs[CreateSurveyQuestionsTourItemsEnum.TEMPLATE]} style={{ width: 350 }}>
                  <SelectFormItemComponent
                    required
                    style={{ width: 380 }}
                    label={I18n.t("template")}
                    name={["answerTemplate", "type"]}
                    values={Object.keys(TemplateEnum).map((key) => ({
                      value: key,
                      disabled: Boolean(questions.find(isWelcomePageBlock)) && key === TemplateEnum.START_BLOCK,
                      label: (
                        <Tooltip placement={"right"} mouseEnterDelay={1} title={I18n.t(`templateTooltips.${key}`)}>
                          <div>
                            <span>{templateIcons[key as TemplateEnum]}</span>
                            <span style={{ marginLeft: 10 }}>{getTemplateLabels()[key as TemplateEnum]}</span>
                          </div>
                        </Tooltip>
                      ),
                    }))}
                  />
                </div>
                {!isChannel(ChannelEnum.SMS) && !isWelcome(question) && questions?.length > 0 && (
                  <>
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const name = getFieldValue("name");
                        const order = getFieldValue("order");
                        const _disabled = isWelcome({ name, order });
                        return (
                          <div ref={refs[CreateSurveyQuestionsTourItemsEnum.QUESTION_NAME]} style={{ width: 300 }}>
                            <InputFormItemComponent
                              name={"name"}
                              style={{ width: 380 }}
                              label={I18n.t("title")}
                              disabled={disabled || _disabled}
                            />
                          </div>
                        );
                      }}
                    </Form.Item>
                    {surveyId && (
                      <MediaUpload
                        surveyId={surveyId}
                        questionId={question?.questionId}
                        initial={question?.mediaProperties}
                      />
                    )}
                  </>
                )}

                <div style={{ marginBottom: 10, fontWeight: 600 }}>
                  <Translate value={"content"} />
                </div>
                <div ref={refs[CreateSurveyQuestionsTourItemsEnum.LANGUAGE_SELECTION]}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldsValue }) => {
                      return (
                        <Tabs
                          type={"card"}
                          size={"small"}
                          className={"lang-tabs"}
                          onChange={(langCode) => {
                            let langIdx = 0;
                            const foundLang = languages.find((l, idx) => {
                              langIdx = idx;
                              return l.langCode === langCode;
                            });
                            if (foundLang) {
                              setCurTabLang({ lang: foundLang, index: langIdx });
                            }
                          }}
                          items={languages.map((lang, index) => ({
                            forceRender: true,
                            key: lang.langCode,
                            label: (
                              <div>
                                {hasUnfilledFields(lang.langCode, getFieldsValue()) ? (
                                  <span>
                                    {lang.langName}
                                    <Tooltip title={I18n.t("youHaveUnfilledFields")}>
                                      <ExclamationCircleOutlined style={{ color: "red", marginLeft: 5 }} />
                                    </Tooltip>
                                  </span>
                                ) : (
                                  lang.langName
                                )}
                              </div>
                            ),
                            children: (
                              <Form.Item shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                  const answerType = getFieldValue(["answerTemplate", "type"]);
                                  const isTextNotRequired =
                                    answerType === TemplateEnum.END_BLOCK && redirectToAnotherSite;
                                  const isEndBlock = answerType === TemplateEnum.END_BLOCK;

                                  return (
                                    <div key={index} className={"lang-tab-content"}>
                                      <HiddenValueFormItemComponent
                                        name={["textTranslations", index, "language", "langCode"]}
                                        value={lang.langCode}
                                      />
                                      <HiddenValueFormItemComponent
                                        name={["textTranslations", index, "language", "langName"]}
                                        value={lang.langName}
                                      />
                                      <MdxEditor
                                        required={!isTextNotRequired}
                                        label={I18n.t("questionText")}
                                        name={["textTranslations", index, "text"]}
                                        disabled={isTextNotRequired}
                                        showEmoji
                                      />
                                      {isEndBlock && (
                                        <Checkbox
                                          children={<Translate value={"redirectToAnotherSite"} />}
                                          checked={redirectToAnotherSite}
                                          onChange={({ target }) => handleRedirectToAnotherSiteChange(target.checked)}
                                        />
                                      )}

                                      <div className={"question-templates-container"}>
                                        {templateType &&
                                          templateComponents(index, lang, redirectToAnotherSite)[templateType]}
                                      </div>
                                    </div>
                                  );
                                }}
                              </Form.Item>
                            ),
                          }))}
                        />
                      );
                    }}
                  </Form.Item>
                </div>
              </div>
              <div style={{ maxHeight: 700, maxWidth: 400, display: "inline-table", flexDirection: "column" }}>
                <div style={{ marginLeft: 63, marginBottom: 30 }}>
                  {surveyId && <SurveyEmulator surveyId={surveyId} disabled={disabled || questions.length <= 1} />}
                  <Button
                    type={"primary"}
                    icon={<SettingOutlined />}
                    onClick={() => setShowSettingsModal(true)}
                    ref={refs[CreateSurveyQuestionsTourItemsEnum.QUESTION_SHOW_LOGIC]}
                  >
                    <Translate value={"questionDisplayLogic"} style={{ marginRight: 5 }} /> (
                    {(question?.conditions?.length ?? 0) + (withPreviousAnswer ? 1 : 0)})
                  </Button>
                  <QuestionViewSettingsModal
                    open={showSettingsModal}
                    onClose={handleCloseSettingsModal}
                    updateWithPreviousAnswer={handleUpdateWithPreviousAnswer}
                    form={form}
                  />
                </div>
                <div
                  style={{ minHeight: 470, maxHeight: 470 }}
                  ref={refs[CreateSurveyQuestionsTourItemsEnum.QUESTION_PREVIEW]}
                >
                  <PhoneComponent
                    color={surveyColor || APP_PRIMARY_COLOR}
                    logo={undefined}
                    size={"large"}
                    form={form}
                    redirectToAnotherSite={redirectToAnotherSite}
                  />
                </div>
                <div style={{ marginLeft: 115, marginTop: 40 }} className={"tags-container"}>
                  <div ref={refs[CreateSurveyQuestionsTourItemsEnum.TAGS]}>
                    <TagsFormItemComponent
                      name={"tags"}
                      label={I18n.t("tags")}
                      type={"questions"}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            </div>

            {templateType === TemplateEnum.LOYALTY_INDEX && (
              <div style={{ marginTop: 20, marginBottom: 10, fontSize: 12, color: "#888" }}>
                <span>{I18n.t("npsTrademark")}</span>
              </div>
            )}

            <div className={"btn-actions"}>
              <Button onClick={onCancel} disabled={false}>
                <Translate value={"cancel"} />
              </Button>
              {!disabled && (
                <Button type={"primary"} onClick={form.submit}>
                  <Translate value={"save"} />
                </Button>
              )}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default QuestionEditPage;

const HiddenItems: FC = () => {
  return (
    <>
      <HiddenValueFormItemComponent name={"order"} />
      <HiddenValueFormItemComponent name={"endBlock"} />
      <HiddenValueFormItemComponent name={"questionId"} />
      <HiddenValueFormItemComponent name={"startBlock"} />
      <HiddenValueFormItemComponent name={"kkText"} value={""} />
      <HiddenValueFormItemComponent name={"enText"} value={""} />
      <HiddenValueFormItemComponent name={"ruText"} value={""} />
      <HiddenValueFormItemComponent name={["answerTemplate", "csiParams"]} />
      <HiddenValueFormItemComponent name={["answerTemplate", "answerChoices"]} />
      <HiddenValueFormItemComponent name={["answerTemplate", "addCustomAnswer"]} />
      <HiddenValueFormItemComponent name={["answerTemplate", "customAnswerTextKk"]} value={""} />
      <HiddenValueFormItemComponent name={["answerTemplate", "customAnswerTextEn"]} value={""} />
      <HiddenValueFormItemComponent name={["answerTemplate", "customAnswerTextRu"]} value={""} />
    </>
  );
};
