import { Dayjs } from "dayjs";
import { ScheduleType } from "./scheduleType";
import { SubscriberCondition } from "./subscriberConditionType";
import { I18n } from "react-redux-i18n";

export type DistributionType = {
  readonly distributionId?: string;
  sourceId: SourceEnum;
  readonly status: DistributionStatus;
  readonly dailyLimit: number;
  readonly startDate?: Dayjs | string;
  readonly endDate: Dayjs | string;
  readonly createdAt?: Dayjs | string;
  readonly updatedAt?: Dayjs | string;
  readonly receiverType: ReceiverTypeEnum;
  readonly schedule: ScheduleType;
  readonly subscriberConditions: Array<SubscriberCondition>;
  readonly anonymous: boolean;
  readonly respondentsCount: number;
  readonly surveyId: string;
  readonly emailProviderId: number | null;
  readonly smsProviderId: number | null;
  readonly subsFileCount?: number;
  readonly subsFileName?: string;
  readonly propertyKey?: string;
};

export enum ReceiverTypeEnum {
  MSISDN = "MSISDN",
  EMAIL = "EMAIL",
}

export enum DistributionStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  FINISHED = "FINISHED",
}

export enum SourceEnum {
  CONTACTS = "CONTACTS",
  FILE = "FILE",
}

export const sourcesEnumLabelRu = (): Record<SourceEnum, string> => ({
  [SourceEnum.CONTACTS]: I18n.t("contacts"),
  [SourceEnum.FILE]: I18n.t("fileFromDisc"),
});

const ReceiverTitle: Record<ReceiverTypeEnum, string> = {
  [ReceiverTypeEnum.MSISDN]: "SMS",
  [ReceiverTypeEnum.EMAIL]: "E-MAIL",
};
export const receiverTypes = Object.values(ReceiverTypeEnum).map((it) => ({
  label: ReceiverTitle[it],
  value: it,
}));
